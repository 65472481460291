import React, { useEffect, useState } from "react";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import "../../Styles/v-categories.css";
import noservice from '../../Assets/OBJECTS.svg'
import axios from "axios";
import { BASE_URL } from "../../Services/BaseUrl";
import { FaMapMarkerAlt, FaCalendarAlt } from "react-icons/fa";
const JobsList = () => {
  const navigate = useNavigate();
  const location= useLocation();
  const [jobsList,setJobsList]=useState([]);
  const [jobsListId,setJobListId]=useState([])
  const [error, setError] = useState(null);
  const [jobRole, setJobRole] = useState([]);
  const [jobsId,setJobsId]=useState("");
  const { catname } = location.state || {}; // Using || {} to avoid undefined error
  const token =localStorage.getItem("token");
  const userId = localStorage.getItem('userId');
  const latitude = localStorage.getItem('latitude');
  const longitude = localStorage.getItem('longitude');
  const radius = localStorage.getItem('radius');
  
  
  
  
  
  const fetchJobs = async () => {
    console.log('Fetching services')
    try {
      const response = await axios.get(`${BASE_URL}/Jobs`,{
        params: {
          latitude: latitude,
          longitude: longitude,
          radius: radius,
          jobRoleId: jobsId, // Pass the selected job role ID
        },
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
        setJobsList(response.data);
        console.log(response.data,"jobss...........")
        // setAdvertisement(response.data.ad);
      
    } catch (err) {
      // setError(err.message);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);
  const fetchJobRole = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/JobRoles`, {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
  
      console.log(response.data, "Job roles response");
  
      setJobRole(response.data); // Save the job roles array in state
    } catch (error) {
      console.error("Error fetching job roles", error);
    }
  };
  
  
useEffect(() => {
    fetchJobRole();
}, []);
const fetchJobId = async () => {
  if (!jobsId) {
    console.log("No jobsId selected, skipping API call.");
    return; // Prevent API call if jobsId is not set
  }

  try {
    const response = await axios.get(`${BASE_URL}/Jobs/${jobsId}/jobRole`, {
      params: {
        // userId: userId,
        latitude: latitude,
        longitude: longitude,
        radius: radius,
      },
      headers: {
        Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
      },
    });

    console.log(jobsId, "Selected Job Role ID");
    console.log(response.data, "Jobs for the selected role");
    setJobListId(response.data); // Update the jobs list with the fetched data
  } catch (error) {
    console.error("Error fetching jobs by role", error.response.data);
    setError(error.response?.data?.message); // Set error message
    setJobListId([]); // Clear the job list
  }
};


useEffect(() => {
  fetchJobId();
}, [jobsId]);


  const encodeUri = (val) => {
    if (val && typeof val === "string") {
      if (val.startsWith("http")) {
        return val; // Return the URL as is
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`; // Encode and return
    }
    console.warn("Provided value is null or undefined, returning empty string.");
    return "";
  };

  const decodeImgeString = (str) => {
    if (str && str.split(",").length > 1) {
      const imageUrl = str.split(",")[0];
      return encodeUri(imageUrl);
    } else if (str) {
      return encodeUri(str);
    }
  };

  return (
    <div>
    {/* Subcategory filter dropdown */}
      <div className="legal-services-layout-container">
        <div className="legal-services-container">
          <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
          <div style={{display:"flex",flexDirection:"column",gap:"16px"}}>
          <div className="serv-breadcrumb-line">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb" style={{margin:"0"}}>
            <li className="breadcrumb-item">
              <a href="/home">Home</a>
            </li>
            <li className="breadcrumb-item " aria-current="page">
              <a href="/home">Jobs</a>
            </li>
            {/* <li className="breadcrumb-item active" aria-current="page">
              <a href="/home">{catname}</a>
            </li> */}
          </ol>
        </nav>
      
      </div>
            {/* <h1>{filteredServices.length} services found</h1> */}
          </div>

          <div className="subcategory-filter" style={{display:"flex",gap:"20px",alignItems:"center"}}>
      <label htmlFor="subcategory" className="subcategory">Filter by Job Role:</label>
      <select
  id="subcategory"
  className="form-select"
  value={jobsId}
  onChange={(e) => setJobsId(e.target.value)} // Update jobsId on selection
>
  <option value="" disabled>All</option>
  {jobRole.map((job) => (
    <option key={job.jobRoleId} value={job.jobRoleId}>
      {job.jobRoleName}
    </option>
  ))}
</select>

    </div>
          </div>
          {(jobsId === "" 
  ? jobsList.filter(
      (job) => 
        job.statusId === 2 && 
        new Date(job.jobPlanEndDate) > new Date()
    )
  : jobsListId
).length > 0 ? (
  <div className="legal-services-grid">
    {(jobsId === "" 
      ? jobsList.filter(
          (job) => 
            job.statusId === 2 && 
            new Date(job.jobPlanEndDate) > new Date()
        )
      : jobsListId
    ).map((job) => (
      <div
        key={`${job.jobsId}-${job.jobsId}`} // Combine values to ensure uniqueness
        className="legal-services-card"
        onClick={() =>
          navigate(`/jobs/${job.jobsId}`, {
            state: { jobsId: job.jobsId, catname: job.jobRoleName },
          })
        }
      >
        <img
          src={decodeImgeString(job.images)}
          alt={job.jobRoleName}
          loading="lazy"
          className="legal-services-image"
        />
        <div style={{ padding: "0 20px 10px 20px" }}>
          <h3>{job.adTitle}</h3>
          <p>{job.jobRoleName}</p>
          <p className="legal-services-icon-text">
            <FaCalendarAlt className="legal-services-icon" />{" "}
            {new Date(job.createdDate).toLocaleDateString()}
          </p>
        </div>
      </div>
    ))}
  </div>
) : (
  <div className="nodata">
    <img src={noservice} alt="No service available" />
    <p>{error || "No jobs found for the selected role."}</p>
  </div>
)}






        </div>
        {/* <div className="mylisting-add1" key={advertisement.advertisementId}style={{height:"80%"}}>
          <img
            src={encodeUri(advertisement.imageUrl)}
            loading="lazy"
            alt=""
            className="advertisement"
          />
        </div> */}
      </div>
   
  </div>
  )
}

export default JobsList