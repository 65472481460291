import React, { useState, useEffect } from "react";
import "../../Styles/ServiceForm.css";
import RenderRazorpay from '../../Components/PayModule/PayModule';
import partyicon from "../../Assets/party-icon.svg";
import { useNavigate } from "react-router-dom";
import { FaRegImage } from "react-icons/fa6";
import { BASE_URL } from "../../Services/BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import { MAP_API } from "../../Services/GmapAPI";
import { FaCaretDown, FaCircleQuestion } from "react-icons/fa6";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
// import RenderRazorpay from '../../Components/PayModule/PayModule';
// import service from '../../Assets/services.png'
// import circle from '../../Assets/service-round.png'

const JobsForm = () => {
    const navigate = useNavigate(); // Hook to navigate between routes
    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [jobRole, setJobRole] = useState([])
    const [jobName, setJobName] = useState("")
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [gst,setGst]=useState([]);
  const [freePlan,setFreePlan]=useState(false);
  const [freeAdd, setFreeAdd]=useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [jobPlans,setJobPlans]=useState([]);
  const [errors, setErrors] = useState({});
    // const [gst,setGst]=useState([])
    // const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
    // const [selectedOptions, setSelectedOptions] = useState([]); // State to track selected subcategories
    const userId = localStorage.getItem("userId"); // Get user ID from local storage
    const token = localStorage.getItem("token"); // Get token from local storage
    const latitude = localStorage.getItem('latitude');
    const longitude = localStorage.getItem('longitude');
    const radius = localStorage.getItem('radius');
    const [mapCenter, setMapCenter] = useState({ lat: 17.4065, lng: 78.4772 });
    const handleToggleDropdown = () => {
        setIsOpen((prev) => !prev); // Toggle dropdown visibility
    };

    const fetchJobRole = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/JobRoles`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
                },
            });
            console.log(response, "tfghvbnko0976trdfv")
            setJobRole(response.data);
            console.log(response.data, "jobsslk");
            setJobName(response.data.jobRoleName);
        } catch (error) {
            console.error('Error fetching profile', error);
        }
    };
    useEffect(() => {
        fetchJobRole();
    }, []);


    // const fetchFreeAdd = async () => {
    //     try {
    //       const response = await axios.get(`${BASE_URL}/JobPlans/${userId}/JobPlan`,{
    //         params: {
    //             userId: userId,
    //           },
    //         headers: {
    //           Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
    //         },
    //       });
    //       const advertisement = response.data;
      
    //       // Check if any item in the response has propertyFeeId equal to 0
    //       const hasFreePlan = advertisement.some(fee => fee.jobPlanId === 0);
          
    //       setFreePlan(hasFreePlan);
    //       setFreeAdd(advertisement);
    //       console.log(advertisement, 'property free fees response');
    //     } catch (error) {
    //       console.error('Error fetching property fees', error);
    //     }
    //   };
      const fetchGst = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/Gst`,{
            headers: {
              Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
          });
          setGst(response.data);
          console.log(response.data,"gstt")
        } catch (error) {
          console.error("Error fetching package types:", error);
        }
      };

      const fetchJobPlans = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/Packages/${userId}/jobs`,{
            headers: {
              Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
          });
          setJobPlans(response.data);
          const propertyFeeData = response.data;
          const hasFreePlan = propertyFeeData.some(fee => fee.packagesId === 0);
    
          setFreePlan(hasFreePlan);
          setFreeAdd(propertyFeeData);
          console.log(response.data,"gstt")
        } catch (error) {
          console.error("Error fetching package types:", error);
        }
      };
      
      
      useEffect(() => {
        fetchJobPlans()
        fetchGst();
        // fetchFreeAdd();
      }, []);

      const handleServicePlanChange = (packagesId, packageTypeId, packagePrice, e) => {
        // const num = Number(e.target.value);
    
        // Ensure gstPercent is correctly derived
        const gstPercent = gst[0]?.gstName ? Number(gst[0].gstName) : 0;
      
        // Calculate GST amount
        const gstAmount = packagePrice * (gstPercent / 100);
      
        // Calculate total amount including GST
        const totalamt = Number(packagePrice) + Number(gstAmount);
        setFormData({
            ...formData,
            // jobRoleId: num,
            paymentPrice: packagePrice,
            packagesId: packagesId,
            packageTypeId: packageTypeId,
            gst: gstPercent,
            totalwithGst: totalamt,
          });

      };
      const handleExistedPlanSelection = (packagesId, packageTypeId, packagePrice, e) => {
        // const num = Number(e.target.value);
    
        // Ensure gstPercent is correctly derived
        const gstPercent = gst[0]?.gstName ? Number(gst[0].gstName) : 0;
      
        // Calculate GST amount
        const gstAmount = packagePrice * (gstPercent / 100);
      
        // Calculate total amount including GST
        const totalamt = Number(packagePrice) + Number(gstAmount);
        setFormData({
            ...formData,
            // jobRoleId: num,
            paymentPrice: packagePrice,
            packagesId: packagesId,
            packageTypeId: packageTypeId,
            gst: gstPercent,
            totalwithGst: totalamt,
          });

      };

      useEffect(() => {
        const existingPlan = jobPlans.find((option) => option.planStatus === "existed");
        if (existingPlan) {
          handleExistedPlanSelection(
            existingPlan.packagesId,
            existingPlan.packageTypeId,
            existingPlan.packagePrice
          )
        }
        else{
          setFreePlan(true)
        }
      }, []);



    const handleSelectJobRole = (selectedJob) => {
        const { jobRoleId, jobRoleName } = selectedJob;
        // console.log("Job Role ID:", jobRoleId, "Job Role Name:", jobRoleName);
        setFormData({
            ...formData,
            jobRoleId,
            jobRoleName,
        });
    };


    

    const handleInputChange = (e) => {
        const { name, value } = e.target; // Extract name and value from the event's target
        console.log(name, value, "fetching"); // Logs the input field name and value
        setFormData({ ...formData, [name]: value }); // Updates the state dynamically
    };
    const requiredFields = [
        "adTitle",
        "companyName",
        "qualification",
        // "address",
        "positionType",
        // "carpetArea",
        // "longitude",
        // "latitude",
      ];
      const validateForm = () => {
        const newErrors = {};
      
        // Validate required fields
        requiredFields.forEach((field) => {
          const value = formData[field];
      
          // Check if the field is missing or empty
          if (!value || (typeof value === "string" && value.trim() === "")) {
            newErrors[field] = `${field} is required`;
          }
        });
      
        // Validate "propertyPlanPrice" separately if needed
        // if (!formData.propertyPlanPrice) {
        //   newErrors.propertyPlanPrice = "Please select a plan.";
        // }
      
        // Set errors in state
        setErrors(newErrors);
      
        // If there are validation errors, return false
        if (Object.keys(newErrors).length > 0) {
          setDisplayRazorpay(false); // Ensure Razorpay is disabled on validation failure
          return false;
        }
      
        // If there are no errors, return true
        return true;
      };

    const handleSubmitclick = (e) => {
        e.preventDefault();
    }
    const handleBrowseClick = () => {
        document.getElementById("fileInput").click();
    };
    const handleUpload = async () => {
        console.log(selectedFiles, 'clicked');
        if (selectedFiles.length === 0) {
            toast.warn('No files selected for upload.');
            console.warn('No files selected for upload.');
            return;
        }

        const fileUploadPromises = selectedFiles.map(async (file) => {
            let imgformData = new FormData();
            imgformData.append('postedFile', file);

            try {
                const response = await axios.post(`${BASE_URL}/Upload`, imgformData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
                    },
                    onUploadProgress: (ProgressEvent) => {
                        const percentCompleted = Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total);
                        setUploadProgress(percentCompleted)
                    }
                });
                console.log(response.data, "uploaded")
                console.log(`File uploaded: ${file.name}`);
                return response.data.filePath;
            } catch (error) {
                console.error(`Error uploading ${file.name}:`, error);
                throw error;
            }
        });

        try {
            // Wait for all files to be uploaded
            const uploadedFilePaths = await Promise.all(fileUploadPromises);

            // Join the file paths into a single string separated by commas
            const imageString = uploadedFilePaths.join(',');

            setFormData(prevFormData => ({
                ...prevFormData,
                images: imageString // Set the images as a comma-separated string
            }));
            toast.success("All images uploaded successfully!");
        } catch (error) {
            toast.error('Error uploading some files.');
            console.error('Error uploading files:', error);
        }
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        // Limit the number of images
        const maxImages = 5; // Set the max number of images you want
        if (files.length + selectedFiles.length > maxImages) {
            toast.warn(`You can only upload a maximum of ${maxImages} images.`);
        } else {
            setSelectedFiles([...selectedFiles, ...files]);
        }
    };
    const removeFile = (index) => {
        const updatedFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(updatedFiles);
    };

    const initialFormData = {
      images: "",
      adTitle: "",
      userId:userId,
      jobRoleId: 0,
      jobRoleName: "",
      companyName: "",
      jobLocation: "",
      latitude: "",
      longitude: "",
      salaryFrom: "",
      salaryTo: "",
      positionType: "",
      jobDescription: "",
      qualification: "",
      jobPlanId:0,
    }
    const [formData, setFormData] = useState(initialFormData);

    const handleSubmit = async (orderDetails) => {
        // e.preventDefault();
        setLoading(true);
        try {
            const formDataToSend = {
                images: String(formData.images) ||"",
                adTitle: formData.adTitle ||"",
                jobRoleId: Number(formData.jobRoleId) || 0,
                jobRoleName: formData.jobRoleName,
                companyName:String(formData.companyName) || "",
                jobLocation:String(formData.jobLocation)|| "",
                latitude: String(formData.latitude) || "" ,
                longitude: String(formData.longitude) ||"",
                salaryFrom: String(formData.salaryFrom) ||"",
                salaryTo: String(formData.salaryTo) ||"",
                positionType: String(formData.positionType) ||"",
                userId: userId ||null,
                jobDescription: String(formData.jobDescription) ||"",
                qualification: String(formData.qualification) ||"",
                jobPlanId:formData.jobPlanId || 0,
                packagesId:(formData.packagesId)|| 0,
                packageTypeId:(formData.packageTypeId) ||0,
                gst:String(formData.gst)||"",
                totalwithGst:String(formData.totalwithGst)||"",
                packagePrice:String(formData.paymentPrice) ||"",
                payorderId:orderDetails?.paymentId ||null
            };
            const response = await axios.post(`${BASE_URL}/Jobs`, formDataToSend, {
                headers: {
                    Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
                },
            });
      if (!validateForm()) {
        toast.warn("Please fill all required fields.");
        return; 
      }
            console.log(response, "job details......................")
            // const requiredFields = [
            //   "name",
            //   // "serviceProviderName",
            //   "address",
            //   // "paymentPrice",
            //   "images",
            //   // "latitude",
            //   // "longitude"
            // ];

            // // Check if all required fields are filled
            // const isFormDataComplete = requiredFields.every(
            //   (field) => formData[field] !== null && formData[field] !== undefined && formData[field] !== ""
            // );

            // if (!isFormDataComplete) {
            //   toast.warn("Please fill all required fields.");
            //   setError("Field is required");
            //   setDisplayRazorpay(false);
            //   return; // Stop execution if any required field is missing
            // }

            console.log(response.data, "successful");
            setFormData(initialFormData);
            toast.success('Jobs posted successfully');
            setSelectedFiles([]);  
            fetchJobPlans()        // Clear selected files
            // Clear selected subcategories         
            // setError(null);                // Clear any errors
            // toast.success("Form reset successfully after payment!");
            // handlepayscreen();
        }  catch (error) {
            if (error.response) {
                // Server responded with a status other than 2xx
                console.error("Error response:", error.response);
                toast.error("Error submitting form: " + error.response.data.message || "An error occurred.");
            } else if (error.request) {
                // The request was made but no response was received
                console.error("No response received:", error.request);
                toast.error("No response from server.");
            } else {
                // Something else happened in setting up the request
                console.error("Error setting up request:", error.message);
                toast.error("Error: " + error.message);
            }
            setLoading(false);
        }
        
    };

    const createPayment = async () => {
        // e.preventDefault();
        if (userId === undefined || userId === null) {
          toast.error("Please login to proceed with payment.");
        }
        if (!formData.packagesId) {
            toast.warn("Please select a plan.");
            // setError("Field is required");
            console.log("dfkghin123")
            setDisplayRazorpay(false);
            return; // Stop execution if the property plan price is not selected
          }



    //     const requiredFields = [
    //    "images",
    //    "adTitle",
    //    "latitude",
    //    "longitude",
    //    "address",
    //     ];
      
    //     // Check if all required fields are filled
    //     const isFormDataComplete = requiredFields.every(
    //       (field) => formData[field] !== null && formData[field] !== undefined && formData[field] !== ""
    //     );
      
    //     if (!isFormDataComplete) {
    //       toast.warn("Please fill all required fields.");
    //       setError("Field is required");
    //       setDisplayRazorpay(false);
    //       return; // Stop execution if any required field is missing
    //     }
        setDisplayRazorpay(true);
      } 

      const handlePayment = async (status, orderDetails) => {
        console.log(status, "---starting- ", orderDetails);
        console.log(displayRazorpay, 'before success');
        if (status === "succeeded") {
          setDisplayRazorpay(false);  // Hide payment interface
          console.log(displayRazorpay, 'after setting to false (immediate, before state update)');
      
          // Submit the form
          await handleSubmit(orderDetails);
          
          // Reset form fields and other states after successful payment
          setFormData([]);
          setSelectedFiles([]);          // Clear selected files
          setSelectedOptions([]);        // Clear selected subcategories
        //   setcatid(null);                // Reset category ID
        //   setshowForm(false);            // Hide the form if needed
        //   setError(null);                // Clear any errors
          toast.success("Form reset successfully after payment!");
      
        } else if (status === "cancelled") {
          setDisplayRazorpay(false);
        }
      };




    const handleMapClick = async (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        setFormData((prevData) => ({
            ...prevData,
            latitude: lat,
            longitude: lng,
        }));

        // Reverse geocode to get the address
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MAP_API}`
            );
            if (response.data.results[0]) {
                setFormData((prevData) => ({
                    ...prevData,
                    jobLocation: response.data.results[0].formatted_address,
                }));
            }
        } catch (error) {
            console.error("Error fetching address:", error);
        }
    };
    console.log(formData, "ghbjnmko987ytughbn m")


    const calculateTotalWithGST = () => {
        const gstPercentage = gst[0]?.gstName || 0; 
        const paymentPrice = formData.paymentPrice || 0; 
        const gstAmount = paymentPrice *( gstPercentage / 100); 
        const totalamt = Number(paymentPrice) + Number(gstAmount)
        return totalamt*100 ; 
      };
    

  const apiKey = process.env.NEXT_PUBLIC_APP_RAZORPAY_KEY_ID


    return (
        <div className="sf-cont">
            <ToastContainer/>
            <div className="sf-left-cont">
            <form className="sf-form-input" onSubmit={handleSubmitclick}>
                <div className="sf-2-piece">

                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Ad Title *"
                            name="adTitle"
                            value={formData.adTitle}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    {errors.adTitle && <p style={{ color: "red" }}>{errors.adTitle}</p>}

                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Company Name"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    {errors.companyName && <p style={{ color: "red" }}>{errors.companyName}</p>}

                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Qualification"
                            name="qualification"
                            value={formData.qualification}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    {errors.qualification && <p style={{ color: "red" }}>{errors.qualification}</p>}

                    <div style={{ display: "flex", gap: "10px" }}>

                        <div className="lpm-part-4-1-select">
                            <select
                                className={`lpm-part-4-1-dropdown${formData.jobRoleId ? " active" : ""}`}
                                value={formData.jobRoleName}
                                onChange={(e) => {
                                    const selectedJob = jobRole.find(
                                        (job) => job.jobRoleName === e.target.value
                                    );
                                    handleSelectJobRole(selectedJob);
                                }}
                            >
                                <option value="" disabled>
                                    Job Role
                                </option>
                                {jobRole.map((job) => (
                                    <option key={job.jobRoleId} value={job.jobRoleName}>
                                        {job.jobRoleName}
                                    </option>
                                ))}
                            </select>
                            <FaCaretDown className="dropdown-icon" />
                        </div>
                        <div className="lpm-part-4-1-select">
                            <select
                                className={`lpm-part-4-1-dropdown ${formData.positionType ? " active" : ""}`}
                                name="positionType"
                                value={formData.positionType}
                                onChange={handleInputChange}
                            >
                                <option value="" disabled>
                                    positionType
                                </option>
                                <option value="Full time">Full time</option>
                                <option value="Part time">Part time</option>
                                <option value="Temporary">Temporary</option>
                                <option value="Contract Basis">Contract Basis</option>


                            </select>
                            <FaCaretDown className="dropdown-icon" />
                        </div>
                    {errors.positionType && <p style={{ color: "red" }}>{errors.positionType}</p>}

                    </div>
                    <div className="lpm-part-6-1">
                        <p style={{ padding: "0px" }}>Add Photos</p>
                        <div className="lpm-part-6-upload-img" style={{ padding: "0px" }}>
                            <div className="lpm-part-6-border" onClick={handleBrowseClick}>
                                <FaRegImage className="lpm-part-6-image-icon" />
                                <button type="button">Browse files</button>
                            </div>
                        </div>
                        <input
                            id="fileInput"
                            type="file"
                            multiple
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                        <div className="lpm-part-6-add-pics">
                            {((uploadProgress > 0) && (uploadProgress < 100)) && (
                                <div className="upload-progress">
                                    <p>Uploading:{uploadProgress}%</p>
                                </div>
                            )}
                            <button onClick={handleUpload}>Submit Photos</button>
                        </div>

                        {/* Display selected images */}
                        <div className="selected-files">
                            {selectedFiles.length > 0 && (
                                <ul>
                                    {selectedFiles.map((file, index) => (
                                        <li key={index} style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt={`selected ${index}`}
                                                loading="lazy"
                                                width="100px"
                                                height="100px"
                                            />
                                            <button
                                                onClick={() => removeFile(index)}
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    right: '0',
                                                    background: '#ffffffcc',
                                                    border: 'none',
                                                    borderRadius: "50%",
                                                    cursor: 'pointer',
                                                    color: 'black',
                                                    fontSize: '12px',
                                                }}
                                                aria-label="Remove"
                                            >
                                                ✖
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="salary-content">
                        <div className="col">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Salary From"
                                name="salaryFrom"
                                value={formData.salaryFrom}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Salary To"
                                name="salaryTo"
                                value={formData.salaryTo}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                    </div>
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Job Description"
                            name="jobDescription"
                            value={formData.jobDescription}
                            onChange={handleInputChange}
                            required
                        />
                    </div>


                    <div className="map-container">
                        <LoadScript googleMapsApiKey={MAP_API}>
                            <GoogleMap
                                mapContainerStyle={{
                                    height: "300px",
                                    width: "100%",
                                    borderRadius: "16px",
                                }}
                                center={mapCenter}
                                zoom={10}
                                onClick={handleMapClick}
                            >
                                {formData.latitude && formData.longitude && (
                                    <Marker
                                        position={{
                                            lat: formData.latitude,
                                            lng: formData.longitude,
                                        }}
                                    />
                                )}
                            </GoogleMap>
                        </LoadScript>
                    </div>


                    <div className="row">
                        <div className="mb-3">
                            <p style={{ padding: "16px" }} onChange={handleInputChange}>
                                <strong>Job Location:</strong> {formData.jobLocation}
                            </p>
                        </div>
                    </div>

             
       {freePlan ?
      (<div className="lpm-part-6-final">
      <button
        className="lpm-final-button"
        onClick={() => {
          handleSubmit();
        }}
      >
        3 Months/Free
      </button>
    </div>):
             (
                <>
                {jobPlans.some(plan => plan.planStatus === "existed") ? (
                    <button
                      className="lpm-final-button"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Post Your Jobs
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary btn-lg btn-block"
                      onClick={() => {
                        createPayment();
                      }}
                    >
                      Continue
                    </button>
                  )}
                  </>
                  )}




                </div>

                {/* {freePlan ?
                <div className="lpm-part-6-final">
                    <button
                        className="lpm-final-button"
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        3 Months/Free
                    </button>
                </div> :
                <div className="sf-right-bottom-cont">
                    <button
                        className="btn btn-primary btn-lg btn-block"
                        onClick={() => {
                            createPayment()
                        }}
                    >
                        Continue
                    </button>
                </div>} */}


                {success && (
                    <p className="success-message">Service added successfully!</p>
                )}
            </form>
            </div>
            <div className="sf-right-up">
 
          
 <div className="sf-right-cont">
   <div className="sf-right-top-cont">
{freePlan?
  freeAdd.map((property) => ( 
    <div className="sf-right-top-cont-1" key={property.packagesId}>
       <img src={partyicon} loading="lazy" alt="" />
       <p className="sf-top-cont-desc">Free Trial For {property.planValidity}</p>
     </div>
)):null}
     <div className="sf-right-top-cont-2">
       <div className="sf-right-top-cont-2-text">
         <p className="sf-right-top-cont-2-text1">
           Post your services and grow your business
         </p>
         <p className="sf-right-top-cont-2-text2">
           Post your services with the lowest prices and get
           notified.
         </p>
       </div>
       {freePlan ? <div>
                  <div className="sf-right-top-cont-1">
                    <img src={partyicon} loading="lazy" alt="" />
                    {/* <p className="sf-top-cont-desc">Free Trial For 3 Months</p> */}
                    {freeAdd.map((property) => (    <div key={property.packagesId}>
                      <p className="sf-top-cont-desc">Free Trial For {property.planValidity}</p>       
                        <p className="sf-right-top-cont-2-text2" style={{ color: "black" }}>{property.description}</p>
                      </div>
                    ))}
                  </div></div>:<div className="sf-right-top-cont-2-bills">
                  {jobPlans.map((jobPlan, id) => {
  const isPlanExisted = jobPlan.planStatus === "existed";
  const isChecked = isPlanExisted || jobPlan.packagesId === formData.packagesId;
  const selectedPrice = jobPlan.discountOfferPrice === "0" || jobPlan.discountOfferPrice === null
  ? jobPlan.packagePrice 
  : jobPlan.discountOfferPrice;
  // Automatically select the existed plan if none is already selected
  if (isPlanExisted && !formData.packagesId) {
    handleExistedPlanSelection(
      jobPlan.packagesId,
      jobPlan.packageTypeId,
      selectedPrice
    );
  }

  return (
    <div key={id} className="form-check">
      <input
        className="form-check-input"
        type="radio"
        value={jobPlan.packagesId} // Set the value correctly
        name="jobPlanId"
        checked={isChecked} // Set the checked state
        onChange={(e) =>
          isPlanExisted
          ? handleExistedPlanSelection(
            jobPlan.packagesId,
            jobPlan.packageTypeId,
            selectedPrice
            )
          :
          handleServicePlanChange(
            jobPlan.packagesId,
            jobPlan.packageTypeId,
            selectedPrice,
            e
          )
        }
      />
      <label
        className="form-check-label"
        htmlFor={`flexCheckDefault${id + 1}`}
      >
        {jobPlan.discountOfferPrice > 0?(
        <>
        <span style={{ textDecoration: "line-through", color: "red" }}>
          ₹{jobPlan.packagePrice}
        </span>
        <span style={{ marginLeft: "10px" }}>
          ₹{jobPlan.discountOfferPrice}
        </span>
      </>
        ):
        (<span>₹{jobPlan.packagePrice}</span> 

        )}
         &nbsp;/{jobPlan.noOfAdds} ADS/ {jobPlan.planValidity.split(' ')[0]} + GST ({gst[0]?.gstName}%)
      </label>
    </div>
  );
})}

       </div>}
       {displayRazorpay && (
<RenderRazorpay
amount={calculateTotalWithGST()} 
currency={"INR"}
// orderId={orderDetails.orderId}
keyId={apiKey}
keySecret={process.env.NEXT_PUBLIC_APP_RAZORPAY_KEY_SECRET}
handlePayment={handlePayment}
name={localStorage.getItem('username')}
// name={capitalizeFirstLetter(helper?.loginUserDetail.firstName) + ' ' + capitalizeFirstLetter(helper?.loginUserDetail.lastName)}
/>
)}

     </div>
   </div>

 </div>

</div>
            
        </div>
    )
}


export default JobsForm