import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import Header from "../../Components/HomeComponent/Header";
import "../../Styles/home.css";
import NewProperty from "./NewProperty";
import VillasList from "../../containers/Home/VillaList";
import LocalitiesList from "./LocalitiesList";
import { BASE_URL } from "../../Services/BaseUrl";
import Categories from "../Categories/Categories";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

export default function Home() {
  const [data, setData] = useState([]);
  const [totalProperties, setTotalProperties] = useState("")
  const [totalUsers, setTotalUsers] = useState("")
  const [loading, setLoading] = useState(true);
  const [advertiseData, setAdvertiseData] = useState([]);
  const [comfortLiv, setComfortLiv] = useState([]);
  const [nearbyPropertiesData, setNearbyPropertiesData] = useState([]);
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');
  const latitude = localStorage.getItem('latitude');
  const longitude = localStorage.getItem('longitude');
  const radius = localStorage.getItem('radius');
  const [locationName, setLocationName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  // Handlers for modal open/close
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleShowModal = () => setIsShowModal(true);
  const handleCloseModal = () => setIsShowModal(false);
  const navigate = useNavigate()

  const chunkArray = (arr, size) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };

  // Chunking the data to group 3 items per slide
  const groupedPropertyTypes = chunkArray(nearbyPropertiesData, 5);
  const groupedComfortLiv = chunkArray(comfortLiv, 3);

  const encodeUri = (val) => {
    if (val && typeof val === 'string') {
      if (val.startsWith("http")) {
        return val; // Return the URL as is
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`; // Encode and return
    }

    console.warn("Provided value is null or undefined, returning empty string.");
    return ""; // Or handle it as you see fit
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          localStorage.setItem("latitude", latitude);
          localStorage.setItem("longitude", longitude);
        },
        (error) => {
          console.error("Error fetching location:", error);
          navigate("/");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      navigate("/");
    }
  }, []);


  
  useEffect(() => {
    const fetchLocationName = async () => {
      if (latitude && longitude) {
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
          );
          setLocationName(response.data.address.suburb || "Unknown Location");
        } catch (error) {
          console.error("Error fetching location name:", error);
        }
      }
    };

    fetchLocationName();
  }, [latitude, longitude]);



  const fetchData = async () => {
    console.log(typeof userId, 'in home')
    try {
      const response = await axios.get(`${BASE_URL}/Dashboard`, {
        params: {
          userId: userId,
          latitude: latitude,
          longitude: longitude,
          radius: radius,
        },
        // headers: {
        //   Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        // },

      });
      setTotalProperties(response.data.totalProperties);
      setTotalUsers(response.data.totalUsers);
      console.log(response.data.totalProperties, "Fetched data");
      setData(response.data.propertyTypes);
      setAdvertiseData(response.data.advertisements || []); // Ensure it's an array
      setComfortLiv(response.data.apartments);
      setNearbyPropertiesData(response.data.nearbyProperties);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userId, latitude, longitude, radius]);

  // Log the state after it's been updated
  useEffect(() => {
    console.log("Data:", data);
    console.log("Advertisements:", advertiseData);
    console.log("Comfort Living:", comfortLiv);
    console.log("Nearby Properties:", nearbyPropertiesData);
  }, [data, advertiseData, comfortLiv, nearbyPropertiesData]); // Depend on all relevant state variables

  // Ensure we only access the advertisement images if they exist
  const firstAdImage = advertiseData.length > 0 ? encodeUri(advertiseData[0].imageUrl) : "";
  const firstAdDescription = advertiseData.length > 0 ? advertiseData[0].title : "";
  console.log("First Ad:", firstAdDescription);
  const secondAdImage = advertiseData.length > 1 ? encodeUri(advertiseData[1].imageUrl) : "";
  const secondAdDescription = advertiseData.length > 1 ? advertiseData[1].title : "";
  // console.log(comfortLiv,'comliv')
  return (
    <div className="home-container" style={{ display: "flex", flexDirection: "column", gap: "30px", position: "relative", zIndex: "1", marginTop: "-10%" }}>
      {/* <ToastContainer /> */}
      <Header totalProperties={totalProperties} totalUsers={totalUsers} />
      <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
        <Categories loading={loading} />
        <NewProperty data={data} loading={loading} />
        {advertiseData && (
          <>
           <div className="fh_image" onClick={handleShow}>
           {firstAdImage ? <img src={firstAdImage} alt="" loading="lazy" className="ads-image" />: null}
            </div> 
            <Modal show={showModal} onHide={handleClose} centered>
              <Modal.Header closeButton>
                <Modal.Title>Image Preview</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img
                  src={firstAdImage}
                  alt=""
                  className="img-fluid" // Bootstrap class for responsive image
                  style={{ width: "100%", height: "auto" }}
                />
                <br></br>
                <p style={{ color: "Black" }}>{firstAdDescription}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
        <VillasList comfortLiv={comfortLiv} loading={loading} />

        {/* <LocalitiesList nearbyPropertiesData={nearbyPropertiesData} loading={loading} groupedPropertyTypes={groupedPropertyTypes} /> */}
        {secondAdImage && (
          <>
            <div className="fh_image" onClick={handleShowModal}>
              <img src={secondAdImage} loading="lazy" alt="Featured Housing" />
            </div>
            <Modal show={isShowModal} onHide={handleCloseModal} centered>
              <Modal.Header closeButton>
                <Modal.Title>Image Preview</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img
                  src={secondAdImage}
                  alt="Featured Housing"
                  className="img-fluid" // Bootstrap class for responsive image
                  style={{ width: "100%", height: "auto" }}
                />
                <br></br>
                <p style={{ color: "Black" }}>{secondAdDescription}</p>


              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}
