import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import "../../Styles/VillaList.css";
import Villa from "../../Components/HomeComponent/Villas";
import image from "../../Assets/FH_image.svg";
import { BASE_URL } from "../../Services/BaseUrl";
import propertyimage from "../../Assets/sellinglady.png"; // Dummy image for no properties

const Properties = () => {
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [sellTypes, setSellTypes] = useState([]);
  const [sellTypeId, setSellTypeId] = useState([0]); // Default value as an array [0]
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(false);
  const [propertyTypeId, setPropertyTypeId] = useState();
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem('userId');
  const latitude = localStorage.getItem('latitude');
  const longitude = localStorage.getItem('longitude');
  const radius = localStorage.getItem('radius');

  const decodeImageString = (str) => {
    if (str && str.split(",").length > 1) {
      const imageUrl = str.split(",")[0];
      return encodeUri(imageUrl);
    } else if (str) {
      return encodeUri(str);
    }
  };

  const encodeUri = (val) => {
    if (val && typeof val === "string") {
      if (val.startsWith("http")) {
        return val;
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`;
    }
    console.warn("Provided value is null or undefined, returning empty string.");
    return "";
  };

  const fetchProperties = async () => {
    try {
      // debugger;
      let url = `${BASE_URL}/SellTypes/${sellTypeId[0]}/propertytypes`; // Updated to use the first element of the array

      const params = {
        userId: userId,
        latitude: latitude,
        longitude: longitude,
        radius: radius,
        propertyTypeid: propertyTypeId 
      };

      const response = await axios.get(url, {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProperties(response.data); 
      setError(false); 
      console.log("Properties fetched successfully:", response.data);
    } catch (error) {
      if (error.code === "ERR_BAD_REQUEST" || error.response?.status === 404) {
        setError(true);
        setProperties([]);
      }
      console.error("Error fetching properties:", error);
    }
  };

  const fetchSellTypes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/SellTypes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSellTypes(response.data);

      if (response.data.length > 0) {
        setSellTypeId([response.data[0].sellTypeId]); // Set to array with first sellTypeId
      }
    } catch (error) {
      console.error("Error fetching sell types", error);
    }
  };

  const fetchCategoriesTypes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Types/${sellTypeId[0]}/categoryType`, {
        params: {
          categoryId: 1, // Filter if needed, can be dynamic
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCategories([ ...response.data]);
    } catch (error) {
      console.error("Error fetching category types", error);
    }
  };
  

  useEffect(() => {
    fetchSellTypes(); 
  }, []);

  useEffect(() => {
      fetchCategoriesTypes(); 
      fetchProperties(); 
  }, [sellTypeId]); 

  useEffect(() => {
    if (propertyTypeId) {
      fetchProperties(); 
    }
  }, [propertyTypeId]); 

  return (
    <>
      <div className="villalist-container">
        <div className="villalist-content">
          <h3>Properties</h3>
          <div className="filter">
            <select
              className="select-box"
              onChange={(e) => setSellTypeId([e.target.value])} // Set as array with selected value
              value={sellTypeId[0]} // Use the first element of the array
            >
              {sellTypes.map((sellType) => (
                <option key={sellType.sellTypeId} value={sellType.sellTypeId}>
                  {sellType.sellTypeName}
                </option>
              ))}
            </select>
          </div>
          <div className="filter">
          <select
  className="select-box"
  onChange={(e) => setPropertyTypeId(e.target.value)}
  value={propertyTypeId}
>
  <option value="" disabled selected>
    Select PropertyType
  </option>
  {categories.map((category) => (
    <option
      key={category.propertyTypesId || category.typeId}
      value={category.propertyTypesId || category.typeId}
    >
      {category.typesName}
    </option>
  ))}
</select>


          </div>
        </div>

        {error ? (
          <div className="sp-body-image">
            <img
              src={propertyimage}
              loading="lazy"
              alt="No properties available"
              className="sp-selling-women"
            />
            <p>No Properties Available</p>
          </div>
        ) : properties.length === 0 ? (
          <div className="sp-body-image">
            <img
              src={propertyimage}
              loading="lazy"
              alt="No properties available"
              className="sp-selling-women"
            />
            <p>No Properties Available</p>
          </div>
        ) : (
          <div className="villalist-content-items">
            {properties.map((villa) => (
              <Villa
                key={villa.propertyId}
                propertyId={villa.propertyId}
                favouriteId={villa.favouriteId}
                title={villa.propertyType}
                location={villa.locationName}
                area={villa.area}
                price={villa.propertyPrice}
                rooms={villa.noOfRooms}
                image={decodeImageString(villa.images)}
                isInitiallyFavourite={villa.favouriteId > 0}
              />
            ))}
          </div>
        )}

        <div className="fh_image">
          <img src={image} loading="lazy" alt="Featured Housing" />
        </div>

        <ToastContainer />
      </div>
    </>
  );
};

export default Properties;
