import React from 'react'
import '../../Styles/Terms.css'

const RefundPolicy = () => {
    const policySections = [
        {
          title: "Scope of the Refund Policy",
          details: [
            "This policy applies to payments made for the following services:",
            "Property purchase deposits",
            "Rental agreements",
            "Brokerage fees",
            "Advertising packages for property listings",
            "Other related services",
          ],
        },
        {
          title: "Refund Eligibility",
          details: [
            "Refunds may be granted under the following conditions:",
            "Cancellations by the Business: If the business cancels a service or transaction due to unforeseen circumstances.",
            "Non-Delivery of Service: If the promised service was not delivered within the agreed timeframe or as per the terms outlined in the agreement.",
            "Customer Cancellations: Refunds for customer-initiated cancellations will be subject to the conditions mentioned below.",
          ],
        },
        {
          title: "Non-Refundable Payments",
          details: [
            "The following payments are generally non-refundable:",
            "Non-refundable deposits or booking amounts for property purchases or rentals, as explicitly mentioned in agreements.",
            "Administrative or processing fees.",
            "Services already rendered or initiated (e.g., property inspections, legal documentation, or marketing campaigns).",
          ],
        },
        {
          title: "Refund Process",
          details: [
            "Request Submission: Customers must submit a refund request in writing to [email/website/contact point] within [X days] of the transaction.",
            "Supporting Documents: The request must include proof of payment, agreement terms, and a detailed explanation of the reason for the refund.",
            "Review Period: Refund requests will be reviewed within [X business days] of submission.",
            "Decision Notification: Customers will be notified of the decision within [X days] of review completion.",
          ],
        },
        {
          title: "Refund Amount",
          details: [
            "Refunds will be processed as follows:",
            "Full refund if the service was not initiated or canceled by the business.",
            "Partial refund if services were partially rendered before cancellation.",
            "Deductions may apply for administrative or processing fees.",
          ],
        },
        {
          title: "Mode of Refund",
          details: [
            "Refunds will be issued using the same payment method used for the original transaction unless otherwise requested and approved.",
            "Refund processing times may vary depending on the payment provider.",
          ],
        },
        {
          title: "Exceptions",
          details: [
            "Refunds will not be granted for:",
            "Change of mind by the customer after the initiation of services.",
            "Failure to comply with the terms outlined in the agreement.",
            "Non-refundable clauses explicitly mentioned in the agreement or transaction documents.",
          ],
        },
        {
          title: "Contact Information",
          details: [
            "For any queries or refund-related concerns, please contact us at:",
            "Phone: (+91) 90307 54455",
            "Email: firsthouseforyou@gmail.com",
            "Office Address: H. No. 5-11/110/150, Brindavan Teachers Colony, Ameenpur, Sangareddy District, Telangana - 502032.",
          ],
        },
      ];
    

  return (
    <div className="container">
    <h1>Refund Policy</h1>
    <p className='left-term'><span className='term-title'>Effective Date:</span> <span className='term-content'>01/01/2025</span></p>
    <br></br>
    <br></br>
    <br></br>
    {policySections.map((section, index) => (
      <div key={index} class="terms-content">
        <h2 className='term-title'>{section.title}</h2>
        <ul>
          {section.details.map((detail, idx) => (
            <li key={idx} className='term-content'>{detail}</li>
          ))}
        </ul>
      </div>
    ))}
  </div>
  )
}

export default RefundPolicy