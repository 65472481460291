import React, { useState } from 'react'
import '../../Styles/ContactUs.css';
// import background1 from '../../assets/background1.png';
// import panthulubro_icon from "../../assets/TPanthulubro.png";
import { MdLocationOn } from "react-icons/md";
import { MdWhatsapp } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/ReactToastify.css'
import { BASE_URL } from "../../Services/BaseUrl";


const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  const postContact = async (e) => {
    e.preventDefault();
        toast.success("Message sent successfully!");

    // const contactData = {
    //   name: name,
    //   mobileNumber: mobile,
    //   fromEmail: email,
    //   message: message,
    // };

    console.log(BASE_URL, 'contact data................');

    // try {
    //   // const response = await axios.post("http://20.198.5.41:6550/api/Contactus", contactData);
    //   const response = await axios.post(`${BASE_URL}/api/Contactus`, contactData);
    //   console.log(response, 'after API')
    //   // toast.success(response.data)
    //   if (response.status === 200) {
    //     toast.success("Message sent successfully!");
    //     console.log(response.data); // Log the response data
    //   } else {
    //     toast.error("Failed to send the message.");
    //   }
    // } catch (error) {
    //   console.error("Error posting contact data:", error);
    //   toast.error("An error occurred while sending your message.");
    // }
  };


  return (
    <div className="contact-container">
      <ToastContainer />
      <div className="contact-section">
        <div className="contact-left">
          <h2>Get In Touch</h2>
          <p>
            Have a question, comment, or suggestion? We'd love to hear from you.
            Please fill out the form below and we'll get back to you as soon as possible.
          </p>
          <div className="contact-details">
            <div className='md-loc'>
            <MdLocationOn />
            <p > H.No: 5-11/110/150,Brindavan Teachers Colony,Beeramguda, Ameenpur,Sangareddy District,Telangana State-502032.</p>
            </div>
            <p>< MdWhatsapp />(+91) 90307 54455</p>
            <p><CiMail /> firsthouseforyou@gmail.com</p>
          </div>
        </div>

        <div className="contact-right">
          <h2>Enquire Now</h2>
          <form onSubmit={postContact}>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="tel"
              placeholder="Mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
            />
            <textarea
              placeholder="Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>

        <div className="map-container">
  <iframe
    title="Google Map"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3803.3948912087155!2d78.5448788!3d17.3454783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb999998a78615%3A0x434f0ec2ca21e855!2sSri%20Lalitha%20Meenakshi%20Nilayam!5e0!3m2!1sen!2sin!4v1698551174775!5m2!1sen!2sin"
    width="100%"
    height="300"
    allowFullScreen=""
    loading="lazy"
  ></iframe>
</div>

      </div>
    </div>
  )
}

export default ContactUs