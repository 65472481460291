import React, { useEffect, useState, } from 'react';
import { useNavigate } from 'react-router-dom';
import VillasList from "../../Components/HomeComponent/Villas";
// import ServicesCard from "../../Components/HomeComponent/ServicesCard"; // Assuming ServicesCard is imported correctly
import { BASE_URL } from "../../Services/BaseUrl";
import nofav from '../../Assets/No Fav.png';
import heart from '../../Assets/heart.svg';
import { toast } from 'react-toastify';

import axios from "axios";
import '../../Styles/Favourites.css';
import { ToastContainer } from 'react-toastify';
import { FaMapMarkerAlt, FaCalendarAlt } from "react-icons/fa";


const Favourites = () => {
  const userId = localStorage.getItem('userId');
  const [favourites, setFavourites] = useState([]);
  const token = localStorage.getItem('token');
  const [favouritesState, setFavouritesState] = useState({});

  const navigate=useNavigate()

  const encodeUri = (val) => {
    if (val && typeof val === 'string') {
      if (val.startsWith("http")) {
        console.log(val.startsWith("http"),"encodehttp")
        return val;
      }
      return `${BASE_URL}/Upload/${encodeURI(val)}`;
    }
    return "";
  };

  const decodeImgeString = (str) => {
    if (str && str.split(",").length > 1) {
      console.log("decodeImgeString",str && str.split(","))
      console.log("encodeimg",str.split(",")[0])
      return encodeUri(str.split(",")[0]);
      
    } else if (str) {
      console.log("error str",str)

      return encodeUri(str);
    }
  };


  
  const fetchFavourites = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Favourites/${userId}/flag`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Favourites response:", response);
      setFavourites(response.data);
      console.log(response.data,"API lo response")
    } catch (error) {
      console.error("Error fetching favourites:", error);
    }
  };

  useEffect(() => {
    fetchFavourites();
  }, []);


  const handleRemoveFavourites = async (favouriteId) => {
    if (!userId) {
      toast.warn("Please log in to remove from favourites.");
      return;
    }
  
    try {
      const response = await axios.post(`${BASE_URL}/Favourites/${favouriteId}/delete`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200 || response.status === 204) {
        toast.success("Removed from favourites!");

        // setFavourites((prevFavourites) =>
        //   prevFavourites.filter((fav) => fav.servicesId !== servicesId)
        // );
  
        fetchFavourites();
      } else {
        throw new Error("Failed to remove from favourites");
      }
    } catch (error) {
      console.error("Error removing from favourites:", error);
      toast.error("Failed to remove from favourites.");
    }
  };
  
console.log("favourites lo items",favourites)
  return (
    <div style={{ width: "100vw", height:"100vh", padding: "15px 25px" }}>
    {favourites && favourites.length > 0 ? (
      <div className="favourites-container">
        {favourites.map((item) => {
          if (item.propertyId) {
            return (
              <VillasList
                key={item.favouriteId}
                favouriteId={item.favouriteId}
                propertyId={item.propertyId}
                title={item.adTitle}
                location={item.locationName}
                area={item.area}
                rooms={item.noOfRooms}
                price={item.propertyPrice}
                image={decodeImgeString(item.images)}
                isInitiallyFavourite={item.favouriteId > 0}
                fetchFavourites={fetchFavourites}
              />
            );
          } else if (item.servicesId) {
            return (
              <div style={{display:"flex",gap:"10px"}}>
              <div key={item.favouriteId} className="legal-services-card2">
                <div className="service-image">
                  <img
                    src={decodeImgeString(item.serviceImages)}
                    alt={item.servicePlanName}
                    loading="lazy"
                    className="legal-services-image2"
                    onClick={() =>
                      navigate(`/categorie/${item.servicesId}`, {
                        state: {
                          categoryId: item.categoryId,
                          catname: item.categoryName,
                        },
                      })
                    }
                  />
                  <div
                    className="card-fav-icon"
                    style={{
                      backdropFilter: "blur(5px)",
                      background: "rgba(0, 45, 58, 0.50)",
                      borderRadius: "6px",
                      height: "30px",
                      width: "30px",
                      zIndex: "1",
                    }}
                  >
                 
                      {favouritesState && <img
                             src={heart} // Replace with heart icon path
                        alt="heart"
                        onClick={() => handleRemoveFavourites(item.favouriteId)}
                        style={{ height: "30px", width: "30px" }}
                      />}
                 
                  </div>
                </div>
                <div style={{ padding: "0 20px 10px 20px" }}>
                  <h3>{item.adtitle}</h3>
                  <p>{item.categoryName}</p>
                  <p className="legal-services-icon-text">
                    <FaMapMarkerAlt className="legal-services-icon" />{" "}
                    {item.stateBarCouncil}
                  </p>
                  <p className="legal-services-icon-text">
                    <FaCalendarAlt className="legal-services-icon" />{" "}
                    {new Date(item.createdDate).toLocaleDateString()}
                  </p>
                </div>
              </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    ) : (
      <div className="no-favourites">
        <img src={nofav} alt="No Favourites" />
      </div>
    )}
    <ToastContainer />
  </div>
  );
};

export default Favourites;
