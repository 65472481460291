import React, { useEffect, useState } from 'react';
// import facebook from '../Assets/fh_fb.svg';
// import instagram from '../Assets/fh_insta.svg';
// import pinterest from '../Assets/fh_pinterest.svg';
// import whatsapp from '../Assets/fh_watsapp.svg';
// import youtube from '../Assets/fh_youtube.svg';
import { BASE_URL } from "../Services/BaseUrl";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../Assets/firsthouse.svg';
import googlestore from '../Assets/Googlestore.svg'
import appstore from '../Assets/appstore.svg';

const Footer = () => {
    const [footerData, setFooterData] = useState([]);
    const [locationId, setLocationId] = useState("");
    const token = localStorage.getItem('token'); // Assuming token is stored in local storage
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');
    const latitude = localStorage.getItem('latitude');
    const longitude = localStorage.getItem('longitude');
    const radius = localStorage.getItem('radius');

    const fetchFooterData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/Dashboard/test`,          {
                params: {
                    userId: userId,
                    latitude: latitude,
                    longitude: longitude,
                    radius: radius,
                  },
                headers: {
                  Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
                },
              });
            setFooterData(response.data);
            console.log(response.data, "footerData");
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchFooterData();
    }, []);

    const onSubmitHandler = (locationId) => {
        setLocationId(locationId);
        const params = new URLSearchParams();
        params.append("locationId", locationId);
        console.log(locationId, "locationId");
        navigate(`/villas-list?${params.toString()}`);
    };

    // Split footerData into three columns
    const third = Math.ceil(footerData.length / 3);
    const footerDataColumn1 = footerData.slice(0, third);
    const footerDataColumn2 = footerData.slice(third, third * 2);
    const footerDataColumn3 = footerData.slice(third * 2);

    return (
        // <div className='main-footer'>
            <div className='footer2'>
            <div className='footer-logo'>
                        {/* <img src={logo} className='logo-footer' alt="First House Logo" /> */}
                        <p>Copyright © 2024 First House</p>
                        <a href='/Terms&Conditions' className='TandC'>Terms & Conditions</a>
                        <a href='/privacyPolicy'className='TandC'>Privacy Policy</a>
                        <a href='/contact-us'className='TandC'>Contact us</a>
                        <a href='/Refundpolicy'className='TandC'>Refund Policy</a>
                        <a href='/shipping&deliverypolicy'className='TandC'>Shipping & Delivery Policy</a>
                    </div>
     
                <div className="footer-googlestore">
  <a href="https://play.google.com/store/apps/details?id=com.Firsthouse&pli=1" target="_blank" rel="noopener noreferrer">
    <img src={googlestore} alt="Google Play Store" className="googlestore" />
  </a>
  <a href="https://play.google.com/store/apps/details?id=com.Firsthouse&pli=1" target="_blank" rel="noopener noreferrer">
    <img src={appstore} alt="Google Play Store" className="googlestore" />
  </a>
</div>

            </div>
        // </div>
    );
};

export default Footer;
