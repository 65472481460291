import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { FaCaretDown, FaCircleQuestion, FaRegImage } from "react-icons/fa6";
import { BASE_URL } from "../../Services/BaseUrl";
import { MAP_API } from "../../Services/GmapAPI";
import sellingwomen from "../../Assets/sellinglady.png";
import arrow from "../../Assets/Arrow Left.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../Styles/SellingProperty.css";
import "../../Styles/Selection.css";
import partyicon from "../../Assets/party-icon.svg";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"; // Add Google Maps components
import RenderRazorpay from "../../Components/PayModule/PayModule";

const EditProperty = () => {
  const location = useLocation();
  const{propertyId}=useParams();
  const navigate = useNavigate();
  const [showfrom, setShowForm] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [advertisement, setAdvertisement] = useState("");
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [propertyFees,setPropertyFees]=useState([]);
  const [freePropertyFees, setFreePropertyFees]=useState([]);
  const [propertyDetails,setPropertyDetails]=useState({})
  const [freePlan,setFreePlan]=useState(false);
  const [error, setError] = useState(""); 
  const [gst,setGst]=useState([])
  const [categories, setCategories] = useState([]);
  const [year ,setYear]=useState({
    availableFromYear: ""
  })
  const[uploadProgress ,setUploadProgress] = useState(0)
  const userId = localStorage.getItem("userId");
  const token=localStorage.getItem('token');
  const latitude = localStorage.getItem('latitude');
  const longitude = localStorage.getItem('longitude');
  const radius = localStorage.getItem('radius');
  const [mapCenter, setMapCenter] = useState({ lat: 17.4065, lng: 78.4772 }); 

  const editInitialFormData={
    sellTypeId:propertyDetails.sellTypeId,
    sellerId:propertyDetails.sellerId,
    sellerImage:propertyDetails.sellerImage,
    sellTypeName:propertyDetails.sellTypeName,
    propertyTypesId:propertyDetails.propertyTypesId,
    propertyType:propertyDetails.propertyType,
    locationsId:propertyDetails.locationsId,
    locationName:propertyDetails.locationName,
    mobileNumber:propertyDetails.mobileNumber,
    propertyName:propertyDetails.propertyName, 
    societyName:propertyDetails.societyName,
    adTitle:"",
    address:"",
    area:0,
    availableFrom:"",
    availableFromYear:0,
    bachelorsAllowed:"",
    balconies:0,
    basementType:0,
    bathrooms:0,
    carParking:0,
    carpetArea:0,
    constructionStatus:0,
    facing:"",
    description: "",
    floorNo:0,
    furnishedStatusId: 0,
    furnishedStatusName:"",
    govtApproval:"",
    images:"",
    latitude:"",
    length:"",
    longitude:"",
    maintenance:"",
    noOfRooms:0,
    parking:0,
    plotArea:"",
    possessionStatusId:0,
    possessionStatusName:"",
    propertyFeeId:0,
    propertyPlanPrice:"",
    propertyPrice:"",
    roadWidth:"",
    totalFloors:0,
    userId:propertyDetails.userId,
    viewCount:0,
    viewedUsers:0,
    width:""
    

  }

  const [formData, setFormData] = useState(editInitialFormData);
  const [data, setData] = useState({
    Possession: [],
    FurnishedStatus: [],
    BasementTypes: [],
    // properties: [],
    locations: [],
    sellTypes: [],
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          sellTypesData,
          // propertiesData,
          possessionData,
          furnishedStatusData,
          basementTypesData,
          locationData,
          AdvertisementData,
        ] = await Promise.all([
          axios.get(`${BASE_URL}/SellTypes`),
          // axios.get(`${BASE_URL}/PropertyTypes`),
          axios.get(`${BASE_URL}/PossessionStatus`),
          axios.get(`${BASE_URL}/FurnishedStatus`),
          axios.get(`${BASE_URL}/BasementTypes`),
          axios.get(`${BASE_URL}/Locations`),
          axios.get(`${BASE_URL}/Advertisements/randomAd`),

          {
            headers: {
              Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
          }
        ]);

        setData({
          sellTypes: sellTypesData.data,
          // properties: propertiesData.data,
          Possession: possessionData.data,
          FurnishedStatus: furnishedStatusData.data,
          BasementTypes: basementTypesData.data,
          locations: locationData.data,
          advertisement: AdvertisementData.data,
        });
        // console.log( sellTypesData,'sell')
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    fetchAdvertisements();
  }, [location.state]);

  const {
    sellTypes,
    properties,
    locations,
    BasementTypes,
    FurnishedStatus,
    Possession,
  } = data;
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleInputChange = (e) => {

    setYear({...year,[e.target.name]: e.target.value});


const { name, value, type } = e.target;
let parsedValue;

if (type === "number") {
  parsedValue = value === "" ? "" : parseInt(value, 10); // Allow empty string
} else if (
  name === "selectedPropertyTypeId" ||
  name === "propertyLocationId" ||
  name === "selectedSellType"
) {
  parsedValue = parseInt(value, 10) || 0; // Keep defaulting to 0 for these fields
} else {
  parsedValue = value; // Handle other types
}

setPropertyDetails((prevState) => ({
  ...prevState,
  [name]: parsedValue,
}));

};


const handleMapClick = async (event) => {
  const lat = event.latLng.lat();
  const lng = event.latLng.lng();

  setPropertyDetails((prevData) => ({
    ...prevData,
    latitude: lat,
    longitude: lng,
  }));

  // Reverse geocode to get the address
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MAP_API}`
    );
    if (response.data.results[0]) {
      setPropertyDetails((prevData) => ({
        ...prevData,
        address: response.data.results[0].formatted_address,
      }));
    }
  } catch (error) {
    console.error("Error fetching address:", error);
  }
};
const currentYear = new Date().getFullYear();
const years = Array.from({ length: 10 }, (_, index) => currentYear+ index);
const encodeUri = (val) => {
  // Check if val is not null or undefined
  if (val && typeof val === "string") {
    // Check if the value starts with 'http'
    if (val.startsWith("http")) {
      return val; // Return the URL as is
    }
    const url = encodeURI(val);
    return `${BASE_URL}/Upload/${url}`; // Encode and return
  }

  // Handle the case where val is null or undefined
  console.warn(
    "Provided value is null or undefined, returning empty string."
  );
  return ""; // Or handle it as you see fit
};
const amenities=[
  {name:"Power Back-up"},
  {name:"Cafeteria/Food Court"},
  {name:"Badminton Court"},
  {name:"Community Hall"},
  {name:"Grocery Store"},
  {name:"Lift"},
  {name:"Garden View"},
  {name:"Volley Ball Court"},
  {name:"Gym"},
  {name:"Auditorium"}
]

const handleBrowseClick = () => {
  document.getElementById("fileInput").click();
};

const handleFileChange = (event) => {
  const files = Array.from(event.target.files);
  const maxImages = 20; // Set the max number of images you want
  if (files.length + selectedFiles.length > maxImages) {
    toast.warn(`You can only upload a maximum of ${maxImages} images.`);
  } else {
    setSelectedFiles([...selectedFiles, ...files]);
  }
};

const handleUpload = async () => {
  console.log(selectedFiles, "clicked");
  if (selectedFiles.length === 0) {
    toast.warn("No files selected for upload.");
    console.warn("No files selected for upload.");
    return;
  }

  const fileUploadPromises = selectedFiles.map(async (file) => {
    let imgformData = new FormData();
    imgformData.append("postedFile", file);

    try {
      const response = await axios.post(`${BASE_URL}/Upload`, imgformData, {
        headers: {
          "Content-Type": "multipart/form-data",
             Authorization: `Bearer ${token}`
        },
        onUploadProgress:(ProgressEvent)=>{
          const percentCompleted=Math.round((ProgressEvent.loaded *100)/ProgressEvent.total);
          setUploadProgress(percentCompleted)
        }
      });
      console.log(response.data, "uploaded");
      console.log(`File uploaded: ${file.name}`);
      return response.data.filePath;
    } catch (error) {
      console.error(`Error uploading ${file.name}:`, error);
      throw error;
    }
  });

  try {
    const uploadedFilePaths = await Promise.all(fileUploadPromises);
    const imageString = uploadedFilePaths.join(",");

    setFormData((prevFormData) => ({
      ...prevFormData,
      images: imageString,
    }));


    toast.success("All images uploaded successfully!");
  } catch (error) {
    toast.error("Error uploading some files.");
    console.error("Error uploading files:", error);
  }
};

const removeFile = (index) => {
  const updatedFiles = selectedFiles.filter((_, i) => i !== index);
  setSelectedFiles(updatedFiles);
};

const handleApplyClick = () => {
if(
propertyDetails.sellTypeId &&
propertyDetails.propertyTypesId &&
// propertyDetails.locationsId &&
propertyDetails.propertyName &&
propertyDetails.societyName
)
    setShowForm(true);
    else toast.warn("Please fill in all fields before proceeding.");

};
const handleButtonInputChange = (name, value) => {
  const syntheticEvent = { target: { name: name, value: value } };
  handleInputChange(syntheticEvent);
};
const fetchAdvertisements = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/Advertisements/randomAd`,          {
      headers: {
        Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
      },
    });
    setAdvertisement(response.data);
    // console.log(response.data, 'ad response');
  } catch (error) {
    console.error("Error fetching advertisements", error);
  }
};


const handleContinueClick = async () => {
  // e.preventDefault();

  try {
    const formDataToSend = {
      propertyId:propertyId,
      sellTypeId:propertyDetails.sellTypeId,
      sellerId:propertyDetails.sellerId,
      sellerImage:propertyDetails.sellerImage,
      sellTypeName:propertyDetails.sellTypeName,
      propertyTypesId:propertyDetails.propertyTypesId,
      propertyType:propertyDetails.propertyType,
      locationsId:propertyDetails.locationsId,
      locationName:propertyDetails.locationName,
      mobileNumber:propertyDetails.mobileNumber,
      propertyName:String(propertyDetails.propertyName), 
      societyName:String(propertyDetails.societyName),
      adTitle:propertyDetails.adTitle,
      address:String(propertyDetails.address),
      area:String(propertyDetails.area),
      availableFrom:String(propertyDetails.availableFrom),
      bachelorsAllowed:String(propertyDetails.bachelorsAllowed),
      balconies:propertyDetails.balconies,
      basementType:propertyDetails.basementType,
      bathrooms:propertyDetails.bathrooms,
      carParking:String(propertyDetails.carParking),
      carpetArea:String(propertyDetails.carpetArea),
      constructionStatus:propertyDetails.constructionStatus,
      facing:String(propertyDetails.facing),
      description: String(propertyDetails.description),
      floorNo:String(propertyDetails.floorNo),
      furnishedStatusId: propertyDetails.furnishedStatusId,
      furnishedStatusName:propertyDetails.furnishedStatusName,
      govtApproval:String(propertyDetails.govtApproval),
      images:String( propertyDetails.images),
      latitude:String(propertyDetails.latitude),
      length:String(propertyDetails.length),
      longitude:String(propertyDetails.longitude),
      maintenance:String(propertyDetails.maintenance),
      noOfRooms:propertyDetails.noOfRooms,
      parking:propertyDetails.parking,
      plotArea:String(propertyDetails.plotArea),
      possessionStatusId:propertyDetails.possessionStatusId,
      possessionStatusName:propertyDetails.possessionStatusName,
      propertyFeeId:propertyDetails.propertyFeeId,
      propertyPlanPrice:String(propertyDetails.propertyPlanPrice),
      propertyPrice:propertyDetails.propertyPrice,
      roadWidth:String(propertyDetails.roadWidth),
      totalFloors:String(propertyDetails.totalFloors),
      userId:propertyDetails.userId,
      viewCount:propertyDetails.viewCount,
      viewedUsers:propertyDetails.viewedUsers,
      width:String(propertyDetails.width),
      anemities:String(propertyDetails.anemities)
    };
    console.log(formDataToSend, "formDataToSend");
  

    const response = await axios.post(
      `${BASE_URL}/Properties/${propertyId}`,
      formDataToSend,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      }
    );
   
  
    console.log(response.data, "successful");
    toast.success("Property posted successfully!");
    setFormData(editInitialFormData);
    setShowForm(false);
    setSelectedFiles([]);
  } catch (error) {
    if(error.status === 400)
    console.error("Error submitting form:", error);
    toast.error(error.response?.data || "Error occurred");
  }
};

useEffect(() => {
  fetch(`${BASE_URL}/Properties/${propertyId}`, {
    params:{
     userId:userId
    },
    headers: {
      Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setPropertyDetails(data.vm);
      console.log(data.vm,"propertydetails")

    })
    .catch((error) => console.error("Error fetching villa details:", error));
}, [propertyId]);

// const apiKey = "process.env.NEXT_PUBLIC_APP_RAZORPAY_KEY_ID";
// -
  return (
     <div className="sp-container">
      <ToastContainer />
      <div className="sp-body-container">
        <div className="sp-main-portion">
          <div
            className="back-arrow"
            onClick={() => navigate("/list-property")}
          >
            <img src={arrow} loading="lazy" alt="arrow" />
            <p>Back</p>
          </div>
          <div className="sp-part-1">
          <div className="sp-part-1-head">
              <span>Sell </span>your property
            </div>         
            <div className="sp-part-1-allbuttons">
              {/* Sell Type Dropdown */}
              {/* <select
                className={`form-select custom-arrow ${
                  formData.selectedSellType ? "active" : ""
                }`}
                name="selectedSellType"
                value={formData.selectedSellType}
                onChange={(e) => {
                  setSelectedselltypeido(e.target.value);
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    selectedSellType: e.target.value,
                  }));
                  handleInputChange(e);
                }}
              > */}

                  {/* <input key={sellType.sellTypeId} value={sellType.sellTypeId} disabled>
                    {sellType.sellTypeName}
                  </input> */}
              
              {/* </select> */}

              {/* Property Type Dropdown */}
              {/* <select
                className={`form-select custom-arrow ${
                  formData.selectedPropertyTypeId ? "active" : ""
                }`} // Use formData for the active class
                name="selectedPropertyTypeId"
                value={formData.selectedPropertyTypeId || "select property"} // Bind value to formData
                onChange={(e) => {
                  handleInputChange(e); // Call this to update formData
                }}
              > */}
                {/* <option disabled>select property</option> */}
                {/* {categories.map((category) => ( */}
                  {/* <input
                    key={propertyDetails.propertyTypesId}
                    value={propertyDetails.propertyTypesId}
                    disabled
                  >
                    {propertyDetails.typesName}
                  </input> */}
                {/* ))} */}
              {/* </select> */}
              {/* <select
                className={`form-select custom-arrow ${
                  formData.propertyLocationId ? "active" : ""
                }`} // Use formData for the active class
                name="propertyLocationId"
                value={formData.propertyLocationId || "select location"} // Bind value to formData
                onChange={(e) => {
                  handleInputChange(e); // Update formData through the existing handler
                }}
              > */}
                {/* <option disabled>select location</option> */}
                {/* {locations.map((location) => ( */}
                  {/* <input
                    key={location.locationsId}
                    value={location.locationsId}
                  >
                    {location.locationName}
                  </input> */}
                {/* ))} */}
              {/* </select> */}

              {/* Other Input Fields */}
              <input
                type="text"
                required
                placeholder="Sell Type"
                className={`form-select custom-arrow ${
                  propertyDetails.sellTypeName ? "active" : ""
                }`}
                name="selectedSellType"
                value={propertyDetails.sellTypeName}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                disabled
              />
              <input
                type="text"
                required
                placeholder="Property Type"
                className={`form-select custom-arrow ${
                  propertyDetails.propertyType ? "active" : ""
                }`} 
                name="selectedPropertyTypeId"
                value={propertyDetails.propertyType || "select property"}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                disabled
              />
              {/* <input
                type="text"
                required
                placeholder="location"
                className={`form-select custom-arrow ${
                  propertyDetails.locationName ? "active" : ""
                }`}
                name="propertyLocationId"
                value={propertyDetails.locationName || "select location"} 
                onChange={(e) => {
                  handleInputChange(e);
                }}
              /> */}
              <input
                type="text"
                required
                placeholder="Property Name"
                className={`sp-part-1-input ${
                  propertyDetails.propertyName ? "active" : ""
                }`}
                name="propertyName"
                value={propertyDetails.propertyName}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
              <input
                type="text"
                required
                placeholder="Name of Project/Society"
                className={`sp-part-1-input ${
                  propertyDetails.societyName ? "active" : ""
                }`}
                name="projectName"
                value={propertyDetails.societyName}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
              {/* Apply Button */}
              <button
                className="sp-part-1-apply-btn"
                onClick={handleApplyClick}
              >
                Apply
              </button>
            </div>
        
          </div>
          {!showfrom ? (
            <div className="sp-body-image">
              <img
                src={sellingwomen}
                loading="lazy"
                alt=""
                className="sp-selling-women"
              />
            </div>
          ) : (
            <div className="lpm-cont-full">
              <div className="lpm-container">
                <div className="lpm-part-2">
                <div className="lpm-part-5">
                  <div className="lpm-part-5-head">Ad Title</div>
                  <div className="lpm-part-5-data">
                    {/* <p>Ad Title</p> */}
                    <input
                      type="text"
                      name="adTitle"
                      required
                      placeholder="Enter Title"
                      className={`lpm-part-5-expect ${
                        propertyDetails.adTitle ? "active" : "inactive"
                      }`} 
                      value={propertyDetails.adTitle} 
                      onChange={(e) => handleInputChange(e)} 
                    />
                    {console.log(propertyDetails,"propertyDetails.adTitle")}
                         {error && <p style={{ color: "red" }}>{error}</p>}
                  </div>
                </div>

                  <div className="lpm-part-2-head">Property Features</div>                  
                  {
                    ((propertyDetails.sellTypeId === 1 &&
    (propertyDetails.propertyTypesId === 1 || propertyDetails.propertyTypesId === 2 || formData.propertyTypesId === 3 ||
      propertyDetails.propertyTypesId === 7 || propertyDetails.propertyTypesId === 8 

    )) ||
  (propertyDetails.sellTypeId === 2 &&
                      [1, 2,3,6, 9].includes(propertyDetails.propertyTypesId)))

                      && (
                      <div className="lpm-part-2-first-line">
                        <div className="lpm-part-2-first">
                          <div className="lpm-part-2-1">
                            <p>Floor Number</p>
                            <div className="lpm-part-2-1-allbuttons">
                              {BasementTypes.map((BasementType) => {
                                return (
                                  <button
                                    name="basementType"
                                    className={`lpm-part-2-1-btn ${
                                      propertyDetails.basementType === BasementType.basementTypeId
                                        ? " active"
                                        : ""
                                    }`}
                                    key={BasementType.basementTypeId}
                                    onClick={(e) => {
                                      handleButtonInputChange(
                                        "basementType",
                                        BasementType.basementTypeId
                                      );
                                    }}
                                  >
                                    {BasementType.basementTypeName}
                                  </button>
                                );
                              })}
                            </div>
                          </div>
                          <div className="lpm-part-2-1">
                            <p>Total Floors</p>
                            <div className="lpm-part-2-1-allbuttons">
                              {Array.from({ length: 11 }, (_, index) => {
                                const floorValue = index + 1;
                                return (
                                  <button
                                    key={floorValue}
                                    name="totalFloors"
                                    className={`lpm-part-2-1-btn ${
                                      propertyDetails.totalFloors === floorValue ? "active" : ""
                                    }`}
                                    onClick={() => {
                                      handleButtonInputChange("totalFloors", floorValue);
                                    }}
                                  >
                                    {floorValue.toString().padStart(2, "0")}
                                  </button>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                     )
                  }
                  {
                  (
                    (
                      (propertyDetails.sellTypeId === 1 &&
                        (propertyDetails.propertyTypesId === 1 ||
                          propertyDetails.propertyTypesId === 2 ||
                          propertyDetails.propertyTypesId === 8 
                      )) ||
                      (propertyDetails.sellTypeId === 2 &&
                        (propertyDetails.propertyTypesId === 1||
                          propertyDetails.propertyTypesId === 2))
                    )
                  )
                  
                  && (
                    <div className="lpm-part-2-second-line">
                      <div className="lpm-part-2-second">
                        <div className="lpm-part-2-1">
                          <p>Bed Rooms</p>
                          <div className="lpm-part-2-1-allbuttons">
                            {Array.from({ length: 5 }, (_, index) => {
                              const bedroomval = index + 1;

                              return (
                                <button
                                  key={index + 1}
                                  name="bedrooms"
                                  className={`lpm-part-2-1-btn ${
                                    propertyDetails.bedrooms === bedroomval ? "active" : ""
                                  }`}
                                  onClick={() => {
                                    handleButtonInputChange("bedrooms", bedroomval);
                                  }}
                                >
                                  {bedroomval.toString().padStart(2, "0")}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                        <div className="lpm-part-2-1">
                          <p>Balconies</p>
                          <div className="lpm-part-2-1-allbuttons">
                            {Array.from({ length: 5 }, (_, index) => {
                              const balconiesval = index + 1;

                              return (
                                <button
                                  key={index + 1}
                                  name="balconies"
                                  className={`lpm-part-2-1-btn ${
                                    propertyDetails.balconies === balconiesval ? " active" : ""
                                  }`}
                                  onClick={() => {
                                    handleButtonInputChange("balconies", balconiesval);
                                  }}
                                >
                                  {balconiesval.toString().padStart(2, "0")}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="lpm-part-2-second">
                        <div className="lpm-part-2-1">
                          <p>Bathrooms</p>
                          <div className="lpm-part-2-1-allbuttons">
                            {Array.from({ length: 5 }, (_, index) => {
                              const bathroomval = index + 1;
                              return (
                                <button
                                  key={index + 1}s
                                  name="bathrooms"
                                  className={`lpm-part-2-1-btn ${
                                    propertyDetails.bathrooms === bathroomval ? " active" : ""
                                  }`}
                                  onClick={() => {
                                    handleButtonInputChange("bathrooms", bathroomval);
                                  }}
                                >
                                  {bathroomval.toString().padStart(2, "0")}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                        <div className="lpm-part-2-1">
                          <p>Parking</p>
                          <div className="lpm-part-2-1-allbuttons">
                            {Array.from({ length: 5 }, (_, index) => {
                              const parkingval = index + 1;
                              return (
                                <button
                                  key={index + 1}
                                  name="carParking"
                                  className={`lpm-part-2-1-btn ${
                                    propertyDetails.carParking === parkingval ? " active" : ""
                                  }`}
                                  onClick={() => {
                                    handleButtonInputChange("parking", parkingval);
                                  }}
                                >
                                  {parkingval.toString().padStart(2, "0")}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

<div className="lpm-part-2-second-line">
  {/* Render Road Width always */}
  <div className="lpm-part-2-second">
    <div className="lpm-part-3-area-1">
      <div className="lpm-area-1">
        <p>Road Width</p>
        <FaCircleQuestion />
      </div>
      <div className="lpm-area-1-input">
        <input
          type="text"
          required
          name="roadWidth"
          placeholder="Enter Road Width"
          className={`lpm-area-1-input-place ${
            propertyDetails.roadWidth ? "active" : "inactive"
          }`}
          value={propertyDetails.roadWidth}
          onChange={handleInputChange}
        />
             {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </div>
  </div>

  {/* Render Length and Width if propertyTypeId is 4 or 5 */}
  {(propertyDetails.propertyTypesId === 4 || propertyDetails.propertyTypesId === 5) && (
    <>
      <div className="lpm-part-2-second">
        <div className="lpm-part-3-area-1">
          <div className="lpm-area-1">
            <p>Width</p>
            <FaCircleQuestion />
          </div>
          <div className="lpm-area-1-input">
            <input
              type="number"
              name="width"
              placeholder="Enter Width"
              className={`lpm-area-1-input-place ${
                propertyDetails.width ? "active" : "inactive"
              }`}
              value={propertyDetails.width}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="lpm-part-3-area-1">
          <div className="lpm-area-1">
            <p>Length</p>
            <FaCircleQuestion />
          </div>
          <div className="lpm-area-1-input">
            <input
              type="number"
              name="length"
              placeholder="Enter Length"
              className={`lpm-area-1-input-place ${
                propertyDetails.length ? "active" : "inactive"
              }`}
              value={propertyDetails.length}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </>
  )}

  {/* Render Floor No based on sellTypeId and propertyTypeId conditions */}
    {((propertyDetails.sellTypeId === 1 && 
    (propertyDetails.propertyTypesId === 1 || 
      propertyDetails.propertyTypesId === 2 || 
      propertyDetails.propertyTypesId === 8)) || 
  (propertyDetails.sellTypeId === 2 && 
    (propertyDetails.propertyTypesId === 2 || 
      propertyDetails.propertyTypesId === 3 || 
      propertyDetails.propertyTypesId === 4))) && (
    <div className="lpm-part-2-second">
      <div className="lpm-part-3-area-1">
        <div className="lpm-area-1">
          <p>Floor No</p>
          <FaCircleQuestion />
        </div>
        <div className="lpm-area-1-input">
          <input
            type="number"
            name="floorNo"
            placeholder="Enter Floor No"
            className={`lpm-area-1-input-place ${
              propertyDetails.floorNo ? "active" : "inactive"
            }`}
            value={propertyDetails.floorNo}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  )}
</div>


<div className="lpm-part-2-second-line">
  {/* Render Govt Approval if sellTypeId is 1 */}
  {propertyDetails.sellTypeId === 1 && (
    
    <div className="lpm-part-4-1">
      <p className="lpm-part-4-question">
        Govt Approval{" "}
        <FaCircleQuestion className="lpm-part-4-question-mark" />
      </p>
      <div className="lpm-part-4-1-select">
        {/* <select
          className={`lpm-part-4-1-dropdown ${formData.GovtApproval ? " active" : ""}`}
          name="GovtApproval"
          value={formData.GovtApproval}
          onChange={handleInputChange}
        >
          <option value="" disabled>
            choose
          </option>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select> */}

<input
            type="text"
            name="govtApproval" // Ensure this matches the state key
            placeholder="Govt Approval"
            className={`lpm-part-4-1-dropdown ${propertyDetails.govtApproval ? " active" : ""}`} // Conditionally apply active/inactive class
            value={propertyDetails.govtApproval} // Bind input to state
            onChange={handleInputChange} // Handle input change
          />
        {/* <FaCaretDown className="dropdown-icon" /> */}
      </div>
    </div>
  )}

  {/* Always render Facing */}
  <div className="lpm-part-4-1">
    <p className="lpm-part-4-question">
      Facing{" "}
      <FaCircleQuestion className="lpm-part-4-question-mark" />
    </p>
    <div className="lpm-part-4-1-select">
      <select
        className={`lpm-part-4-1-dropdown ${propertyDetails.facing ? " active" : ""}`}
        name="facing"
        value={propertyDetails.facing}
        onChange={handleInputChange}
      >
        <option value="" disabled>
          choose
        </option>
        <option value="North">North</option>
        <option value="North-East">North-East</option>
        <option value="East">East</option>
        <option value="South-East">South-East</option>
        <option value="South">South</option>
        <option value="South-West">South-West</option>
        <option value="West">West</option>
        <option value="North-West">North-West</option>
      </select>
      <FaCaretDown className="dropdown-icon" />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  </div>

  {/* Render Bachelors Allowed only if sellTypeId is 2 and propertyTypeId is 1 or 2 */}
  {  (propertyDetails.sellTypeId === 2 && 
    (propertyDetails.propertyTypesId === 2 || 
      propertyDetails.propertyTypesId === 3 || 
      propertyDetails.propertyTypesId === 4)) && (
    <div className="lpm-part-4-1">
      <p className="lpm-part-4-question">
        Bachelors Allowed{" "}
        <FaCircleQuestion className="lpm-part-4-question-mark" />
      </p>
      <div className="lpm-part-4-1-select">
        <select
          className={`lpm-part-4-1-dropdown ${propertyDetails.bachelorsAllowed ? " active" : ""}`}
          name="bachelorsAllowed"
          value={propertyDetails.bachelorsAllowed}
          onChange={handleInputChange}
        >
          <option value="" disabled>
            choose
          </option>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
        <FaCaretDown className="dropdown-icon" />
      </div>
    </div>
  )}
</div>

<div className="lpm-part-2-second-line">
  <div className="lpm-part-2-second">
    {/* Render Plot Area if propertyTypeId is 4 or 5 */}
    {(propertyDetails.propertyTypesId === 4 || propertyDetails.propertyTypesId === 5) && (
      <div className="lpm-part-3-area-1">
        <div className="lpm-area-1">
          <p>Plot Area</p>
          <FaCircleQuestion />
        </div>
        <div className="lpm-area-1-input">
          <input
            type="number"
            name="plotArea" // Ensure this matches the state key
            placeholder="Enter Width "
            className={`lpm-area-1-input-place ${propertyDetails.plotArea ? "active" : "inactive"}`} // Conditionally apply active/inactive class
            value={propertyDetails.plotArea} // Bind input to state
            onChange={handleInputChange} // Handle input change
          />
        </div>
      </div>
    )}
    
    {/* Render Maintenance if propertyTypeId is 1 or 2, or if sellTypeId is 2 and propertyTypeId is 6 or 7 */}
    { (propertyDetails.propertyTypesId === 1 || 
   propertyDetails.propertyTypesId === 2 || 
   propertyDetails.propertyTypesId === 8 || 
   (propertyDetails.sellTypeId === 2 && 
    (propertyDetails.propertyTypesId === 1 || 
      propertyDetails.propertyTypesId === 6))) && (
      <div className="lpm-part-3-area-1">
        <div className="lpm-area-1">
          <p>Maintenance</p>
          <FaCircleQuestion />
        </div>
        <div className="lpm-area-1-input">
          <input
            type="number"
            name="maintenance" // Ensure this matches the state key
            placeholder="Enter Maintenance"
            className={`lpm-area-1-input-place ${propertyDetails.maintenance ? "active" : "inactive"}`} // Conditionally apply active/inactive class
            value={propertyDetails.maintenance} // Bind input to state
            onChange={handleInputChange} // Handle input change
          />
        </div>
      </div>
    )}
  </div>
  <div className="lpm-part-2-third-line">
    {/* Render Furnished Status if propertyTypeId is 1 or 2 */}
    {(propertyDetails.sellTypeId === 1 && (propertyDetails.propertyTypesId === 1 || 
   propertyDetails.propertyTypesId === 2 || 
   propertyDetails.propertyTypesId === 8)) && (
      <div className="lpm-part-2-1" style={{minWidth:"510px"}}>
        <p>Furnished Status</p>
        <div className="lpm-part-2-1-1-allbuttons">
          {FurnishedStatus.map((FurnishedStat) => {
            return (
              <button
                name="furnishedStatus"
                value={FurnishedStat.furnishedStatusName}
                className={`lpm-part-2-1-1-btn ${
                  formData.furnishedStatusId === FurnishedStat.furnishedStatusId ? " active" : ""
                }`}
                key={FurnishedStat.furnishedStatusId}
                onClick={(e) => {
                  handleButtonInputChange("furnishedStatusId", FurnishedStat.furnishedStatusId);
                }}
              >
                {FurnishedStat.furnishedStatusName}
              </button>
            );
          })}
        </div>
      </div>
    )}
  </div>
</div>

                </div>
                <div className="lpm-part-3">
  <div className="lpm-part-3-head">
    <div>Property Area</div>
    <p>Please provide either carpet area or super area</p>
  </div>
  <div className="lpm-part-3-area">
    {/* Render Carpet Area only if propertyTypeId is not 4 or 5 */}
      <div className="lpm-part-3-area-1">
        <div className="lpm-area-1">
          <p>Carpet Area</p>
          <FaCircleQuestion />
        </div>
        <div className="lpm-area-1-input">
          <input
            type="text"
            name="carpetArea" // Ensure this matches the state key
            placeholder="Enter carpet area details"
            className={`lpm-area-1-input-place ${propertyDetails.carpetArea ? "active" : "inactive"}`} // Conditionally apply active/inactive class
            value={propertyDetails.carpetArea} // Bind input to state
            onChange={handleInputChange} // Handle input change
          />
          {/* <select>
            <option value="sqft">Sq. Ft.</option>
            <option value="sqm">Sq. M.</option>
            <option value="acre">Acre</option>
          </select> */}
               {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
    <div className="lpm-part-3-area-1">
      <div className="lpm-area-1">
        <p>Super Area</p>
        <FaCircleQuestion />
      </div>
      <div className="lpm-area-1-input">
        <input
          type="text"
          name="superArea"
          placeholder="Enter super area details"
          className={`lpm-area-1-input-place ${propertyDetails.superArea ? "active" : "inactive"}`} // Conditionally apply active/inactive class
          value={propertyDetails.superArea} // Bind input to state
          onChange={handleInputChange} // Handle input change
        />
        {/* <select>
          <option value="sqft">Sq. Ft.</option>
          <option value="sqm">Sq. M.</option>
          <option value="acre">Acre</option>
        </select> */}
      </div>
    </div>
  </div>
</div>


<div className="lpm-part-4">
  <div className="lpm-part-4-head">
    Transaction Type, Property Availability
  </div>
  <div className="lpm-part-4-body">

    {/* Available From Section moved to the front */}
    <div className="lpm-part-4-1">
      <p className="lpm-part-4-question">
        Available from{" "}
        <FaCircleQuestion className="lpm-part-4-question-mark" />
      </p>
      <div className="lpm-part-4-1-allbuttonss">
        <div className="lpm-part-4-1-select">
          <select
            className={`lpm-part-4-1-dropdown ${
              propertyDetails.availableFrom ? " active" : ""
            }`}
            name="availableFrom" // Use name to map to the state
            value={propertyDetails.availableFrom}
            onChange={(e) => {
              handleInputChange(e);
            }}
          >
            <option value="" disabled>
              Select Month
            </option>
            {months.map((month, index) => (
              <option key={index} value={month} style={{ color: "white" }}>
                {month}
              </option>
            ))}
          </select>
          <FaCaretDown className="dropdown-icon" />
        </div>
        <div className="lpm-part-4-1-select">
          <select
            className={`lpm-part-4-1-dropdown ${
              propertyDetails.availableFromYear ? " active" : ""
            }`}
            name="availableFromYear" // Use name to map to the state
            value={propertyDetails.availableFromYear}
            onChange={(e) => {
              handleInputChange(e);
            }}
          >
            <option value="" disabled>
              Select Year
            </option>
            {years.map((year, index) => (
              <option key={index} value={year} style={{ color: "white" }}>
                {year}
              </option>
            ))}
          </select>
          <FaCaretDown className="dropdown-icon" />
        </div>
      </div>
    </div>

    {/* Render Possession Status if sellTypeId is 1 and propertyTypeId is 1 or 2 */}


  </div>
</div>

                <div className="lpm-part-5">
                  <div className="lpm-part-5-head">Price Details</div>
                  <div className="lpm-part-5-data">
                    <p>Expected Price</p>
                    <input
                      type="text"
                      name="propertyPrice"
                      placeholder="Enter price details"
                      className={`lpm-part-5-expect ${
                        propertyDetails.propertyPrice ? "active" : "inactive"
                      }`} // Conditionally apply active/inactive class
                      value={propertyDetails.propertyPrice} // Bind input to state
                      onChange={(e) => handleInputChange(e)} // Handle input change
                    />
                    <div className="lpm-part-5-check">
                      <input type="checkbox" />
                      <label htmlFor="checkbox">Price Negotiable</label>
                    </div>
                  </div>
                </div>
 
                <div className="lpm-part-5">
                  <div className="lpm-part-5-head">Description</div>
                  <div className="lpm-part-5-data">
                    <p>Property Description</p>
                    {/* <input
                      type="text"
                      name="expectedPrice"
                      placeholder="Enter price details"
                      value={formData.expectedPrice} // Bind input to state
                    /> */}
                    <textarea
                      className={`lpm-part-5-expect ${
                        propertyDetails.description ? "active" : "inactive"
                      }`}
                      id="exampleFormControlTextarea1"
                      rows="4"
                      placeholder="Please proved some description in relevance to the property  "
                      name="description" // This should match the state field
                      value={propertyDetails.description}
                      onChange={(e) => handleInputChange(e)}
                    ></textarea>
                  </div>
                </div>
                <div className="map-container">
                  <LoadScript googleMapsApiKey={MAP_API}>
                    <GoogleMap
                      mapContainerStyle={{
                        height: "300px",
                        width: "100%",
                        borderRadius: "16px",
                      }}
                      center={mapCenter}
                      zoom={10}
                      onClick={handleMapClick}
                    >
                      {propertyDetails.latitude && propertyDetails.longitude && (
                        <Marker
                          position={{
                            lat: propertyDetails.latitude,
                            lng: propertyDetails.longitude,
                          }}
                        />
                      )}
                    </GoogleMap>
                  </LoadScript>
                </div>

                <div
                  className="location-info"
                  style={{ marginTop: "20px", padding: "16px 30px" }}
                >
                  <p>
                    <strong>Latitude:</strong> {propertyDetails.latitude}
                  </p>
                  <p>
                    <strong>Longitude:</strong> {propertyDetails.longitude}
                  </p>
                  <p>
                    <strong>Address:</strong> {propertyDetails.address}
                  </p>
                </div>
                <div className="lpm-part-5">
  {(
    (propertyDetails.sellTypeId === 1 && 
      [1, 2, 3, 7, 8].includes(propertyDetails.propertyTypesId)) || 
    (propertyDetails.sellTypeId === 2 && 
      [1, 2, 3, 6, 9].includes(propertyDetails.propertyTypesId))
  ) && (
    <div className="lpm-part-2-second-line">   
      <div className="lpm-part-4-1">
        <p className="lpm-part-4-question">
          Amenities
          <FaCircleQuestion className="lpm-part-4-question-mark" />
        </p>
        <div className="checkbox-container">
          {amenities.map((amenity) => (
            <label key={amenity.name} className="checkbox-label">
              <input
                type="checkbox"
                name="anemities"
                className="checkbox-input"
                value={amenity.name} // Each checkbox has the amenity name as its value
                checked={propertyDetails.anemities.includes(amenity.name)} // Check if the amenity is in the array
                onChange={(e) => {
                  const newAmenities = e.target.checked
                    ? [...propertyDetails.anemities, amenity.name] // Add to the array if checked
                    : propertyDetails.anemities.filter((item) => item !== amenity.name); // Remove if unchecked

                  // Update the state
                  setFormData({ ...formData, anemities: newAmenities });
                }}
              />
              {amenity.name}
            </label>
          ))}
        </div>
      </div>
    </div>
  )}
</div>



                <div className="lpm-part-6">
                  <div className="lpm-part-6-1">
                    <p>Add Photos</p>
                    <div className="lpm-part-6-upload-img">
                      <div
                        className="lpm-part-6-border"
                        onClick={handleBrowseClick}
                      >
                        <FaRegImage className="lpm-part-6-image-icon" />
                        <button type="button">Browse files</button>
                      </div>
                    </div>
                    <input
                      id="fileInput"
                      type="file"
                      multiple
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
  
                    <div className="lpm-part-6-add-pics" style={{display:"flex", gap:"20px"}}>
                   <div> {((uploadProgress>0)&&(uploadProgress < 100)) &&(
          <div className="upload-progress">
            <p>Uploading:{uploadProgress}%</p>
            </div>
        )}</div>
                      <button
                        onClick={handleUpload}
                        disabled={selectedFiles.length < 1}
                      >
                        Submit Photos                        
                      </button>
                    </div>

                    {/* Display selected images */}
                    <div className="selected-files">
                      {selectedFiles.length > 0 && (
                        <ul>
                          {selectedFiles.map((file, index) => (
                            <li
                              key={index}
                              style={{
                                position: "relative",
                                display: "inline-block",
                                margin: "10px",
                              }}
                            >
                              <img
                                src={URL.createObjectURL(file)}
                                alt={`selected ${index}`}
                                loading="lazy"
                                width="100px"
                                height="100px"
                              />
                              <button
                                onClick={() => removeFile(index)}
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  background: "#ffffffcc",
                                  border: "none",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                  color: "black",
                                  fontSize: "12px",
                                }}
                                aria-label="Remove"
                              >
                                ✖
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="lpm-part-6-2">
                    <div className="lpm-part-6-check-points">
                      <input type="checkbox" />
                      <label htmlFor="checkbox">
                        I am posting this property exclusively on property adda
                      </label>
                    </div>

                    <div className="lpm-part-6-check-points">
                      <input type="checkbox" />
                      <label htmlFor="checkbox">
                        I want to receive responses on WhatsApp
                      </label>
                    </div>

                    <div className="lpm-part-6-check-points">
                      <input type="checkbox" />
                      <label htmlFor="checkbox">
                        I am the owner/I have the authority to post this
                        property. I agree not to provide incorrect property
                        information or state a discriminatory preference. In
                        case, the information does not comply with Magicbricks
                        terms, Magicbricks.com has the right to edit/remove the
                        property from their site.
                      </label>
                    </div>
                  </div>
                <div className="lpm-part-6-final">
                    <button
                      className="lpm-final-button"
               onClick={handleContinueClick}
                    >
                Save Changes
                    </button>
                  </div>
                  {/* {displayRazorpay && (
                    <RenderRazorpay
                      amount={calculateTotalWithGST()}
                      currency={"INR"}
                      // orderId={orderDetails.orderId}
                      keyId={apiKey}
                      keySecret={
                        process.env.NEXT_PUBLIC_APP_RAZORPAY_KEY_SECRET
                      }
                      handlePayment={handlePayment}
                      name={localStorage.getItem("username")}
                      // name={capitalizeFirstLetter(helper?.loginUserDetail.firstName) + ' ' + capitalizeFirstLetter(helper?.loginUserDetail.lastName)}
                    />
                  )} */}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="sp-left-content">
        <div className="sp-list-sidebar" key={advertisement.advertisementId}>
          <img src={encodeUri(advertisement.imageUrl)} loading="lazy" alt="" />
        </div>       
        <div className="sf-right-cont">
              <div className="sf-right-top-cont">
                <div className="sf-right-top-cont-2">
                  <div className="sf-right-top-cont-2-text">
                    <p className="sf-right-top-cont-2-text1">
                      Post your properties and grow your business
                    </p>
                    <p className="sf-right-top-cont-2-text2">
                      Post your properties with the lowest prices and get
                      notified.
                    </p>
                  </div>
                 {/* {freePlan? <div>
                   <div className="sf-right-top-cont-1">
                  <img src={partyicon} loading="lazy" alt="" />
                  <p className="sf-top-cont-desc">Free Trial For 3 Months</p>
                  <p>{freePropertyFees.map((property)=>(
                    <div>
                      <p className="sf-right-top-cont-2-text2" style={{color:"black"}}>{property.description}</p>
                    </div>
                  ))}</p>
                </div></div>:<>
                 <div className="sf-right-top-cont-2-bills">
                    {propertyFees.map((plan, id) => (
                      <div key={id} className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          value={plan.propertyFeeId} // Correctly set the value to servicePlan.id
                          // id={`flexCheckDefault${id + 1}`}
                          name="propertyFeeId"
                                       onClick={(e) => {
                  handleButtonInputChange("propertyFeeId", plan.propertyFeeId);
                  handleButtonInputChange("propertyPlanPrice", plan.price);

                }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`flexCheckDefault${id + 1}`}
                        >
                          <span>₹{plan.price}</span> /Month + GST({gst[0]?.gstName}%)
                        </label>
                      </div>
                    ))}
                  </div></>} */}

                </div>
              </div>
   
             
      </div>
      </div>
    </div>
    </div>
  )
}

export default EditProperty