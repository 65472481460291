import React, { useEffect, useState } from "react";
import { createSignalRContext } from "react-signalr/signalr";
import * as signalR from "@microsoft/signalr";
import "../../Styles/Chat.css";
import { VscThreeBars } from "react-icons/vsc";
import { GoDotFill } from "react-icons/go";
import chatdp from '../../Assets/chat-dp.svg';
import chatdpind from "../../Assets/chat-dp-ind.svg";
import hands from "../../Assets/Hand Stars.svg";
import menudots from "../../Assets/Menu Dots.svg";
import phone from "../../Assets/Phone Rounded.svg";
import attachFile from "../../Assets/Messenger Icon.svg";
import attachPic from "../../Assets/Messenger Icon (1).svg";
import sendbutton from "../../Assets/Button.svg";
import PromotionBanner from "./PromotionBanner";
import { BiArrowBack } from "react-icons/bi";
// import { Sync } from "@material-ui/icons";
import { BASE_URL } from "../../Services/BaseUrl";
import axios from "axios";



const Chat = () => {
  // State for managing selected chat and typing indicator

  const [isTyping, setIsTyping] = useState(false);
  const [showChat, setshowChat] = useState(true);
  const [chatLists, setchatList] = useState([]);
  
  const [connectionStatus, setConnectionStatus] = useState("Disconnected");
  const [newMessage, setNewMessage] = useState("");
  const [connection, setConnection] = useState(null);
  const [messages, setMessages] = useState([]);
  const [receiverId, setReceiverId] = useState(""); // Set the receiver's ID
  // const [connection, setConnection] = useState(null);
  // const [messages, setMessages] = useState([]);
  // const [newMessage, setNewMessage] = useState("");
  const userId = localStorage.getItem('userId');
  // Find the active chat data based on selected chat ID
  const [activeChatId, setActiveChatId] = useState(chatLists[0]?.id || null); // Default to the first chat or null
  const activeChat = chatLists.find((chat) => chat.id === activeChatId);
  console.log(activeChatId, "akldjhliu")



  const encodeUri = (val) => {
    console.log(val,"encode");
    // Check if val is not null or undefined
    if (val && typeof val === 'string') {
      // Check if the value starts with 'http'
      if (val.startsWith("http")) {
        return val; // Return the URL as is
      }
      const url = encodeURI(val);
      console.log(`${BASE_URL}/Upload/${url}`)
      return `${BASE_URL}/Upload/${url}`; // Encode and return
    }

    // Handle the case where val is null or undefined
    console.warn("Provided value is null or undefined, returning empty string.");
    return ""; // Or handle it as you see fit
  };

  // Function to handle chat selection from the list
  const handleChatClick = (chatId) => {
    setActiveChatId(chatId);
    setIsTyping(false);
  };

  const handleShowChat = () => {
    setshowChat(true);
  }

  const fetchChat = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Chat/${userId}/GetUserchats`);
      console.log(response, "chat details")
      setchatList(response.data);
    } catch (error) {
      console.error("Error fetching chat list:", error);
    }
  }
  useEffect(() => {
    fetchChat();
  }, []);



  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl("http://20.198.5.41:9001/chathub") // Replace with your SignalR server URL
      .withAutomaticReconnect()
      .build();

    newConnection.start()
      .then(() => {
        console.log("Connected to SignalR ChatHub");

        // Listen for messages from other users
        newConnection.on("ReceiveMessage", (fromUserId, message, fromUsername) => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { fromUserId, message, fromUsername },
          ]);
        });
      })
      .catch((error) => console.error("Connection failed: ", error));

    setConnection(newConnection);

    return () => {
      newConnection.stop();
    };
  }, []);

  // Sending a message
  const sendMessage = async () => {
    if (connection && newMessage.trim() !== "" && receiverId.trim() !== "") {
      try {
        await connection.invoke("SendMessage", userId, receiverId, newMessage, );
        setMessages((prevMessages) => [
          ...prevMessages,
          { message: newMessage,  },
        ]);
        setNewMessage("");
      } catch (error) {
        console.error("Send message failed: ", error);
      }
    }
  };




  return (
    <div className="my-chat-container">
      {/* Left Sidebar */}
      <div className="chat-left-cont">
        {showChat &&
          <div className="chat-left-first-cont">
            {chatLists.map((chat) => (
              <div
                key={chat.chatMessageId}
                className={`chat-item ${chat.chatMessageId === activeChatId ? "active" : ""}`}
                onClick={() => handleChatClick(chat.chatMessageId)}
              >
                <div className="chat-avatar">
                  <img src={encodeUri(chat.fromProfileImage)} alt={chat.fromUserName} />
                </div>
                <div className="chat-details">
                  <h4
                    className="my-secondary-text"
                    style={{ color: "#00DBBF" }}
                  >
                    {chat.fromUserName}
                  </h4>
                  <p className="my-primary-text">{chat.message}</p>
                  {/* <p className="my-primary-text-dark">{chat.messages[(chat.messages.length)-1]}</p> */}
                </div>
              </div>
            ))}
          </div>
        }
        {/* Chat Window */}
        <div>
        {messages.map((msg, index) => (
          <div key={index} style={{ textAlign: msg.fromUserId === userId ? "right" : "left" }}>
            <strong>{msg.fromUsername}:</strong> {msg.message}
          </div>
        ))}
      </div>
      <input
        type="text"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Type a message..."
      />
      <button onClick={sendMessage}>Send</button>

      </div>

      {/* Right Sidebar */}
      <div className="chat-right-cont">
        <PromotionBanner />
      </div>
    </div>
  );
};

export default Chat;

