import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "../Layout";
import Home from "../containers/Home/home";
import VillaList from "../containers/VillaList/VillaList";
import VillaDetails from '../containers/VillaList/VillaDetails';
import Chat from '../Components/chat/Chat';
import ServiceList from '../Components/services/ServiceList';
import ServicePage from '../Components/services/servicePage'
import ServiceForm from "../Components/services/ServiceForm";
import BuyPackages from '../Components/ByPackages/BuyPackages';
import Login from '../containers/Auth/Login';
import Editprofile from '../Components/EditProfile/Editprofile';
import Otp from '../containers/Auth/Otp';
import Mylisting1 from '../containers/MyListing/Mylisting1';
import Mylisting2 from '../containers/MyListing/Mylisting2';
import Mylisting3 from '../containers/MyListing/Mylisting3';
// import ListProperty from '../containers/ListProperty/Selection';
import PostAd1 from '../containers/Postadd/PostAd1';
// import PostAd2 from '../containers/Postadd/PostAd2';
import ListProperty from '../containers/ListProperty/ListProperty';
import SellingProperty from '../containers/ListProperty/SellingProperty';
import Categories from '../containers/Categories/v-Categories';
import Categoriepage from '../containers/Categories/v-Categoriepage';
import Chatpopup from '../containers/Categories/v-Chat-popup';
import ScrollToTop from "../Components/ScrollTop/ScrollToTop";
// import { Favorite } from "@material-ui/icons";
import Favourites from "../containers/Favourites/Favourites";
import Properties from "../containers/Categories/Properties";
import CategoriesPage from "../containers/Categories/CategoriesPage";
import Jobs from "../containers/Jobs/Jobs";
import JobsForm from "../containers/Jobs/JobsForm";
import JobsList from "../containers/Jobs/JobsList";
import JobsPage from "../containers/Jobs/JobsPage";
import EditProperty from "../containers/ListProperty/EditProperty";
import EditService from "../Components/services/EditService";
import PropertyList from "../containers/VillaList/PropertyList";
import Invoices from '../containers/Invoices/Invoices'
import TermsAndConditions from "../Components/TAndC/TermsAndConditions";
import PrivacyPolicy from "../Components/TAndC/PrivacyPolicy";
import ContactUs from "../Components/TAndC/ContactUs";
import RefundPolicy from "../Components/RefundPolicy/RefundPolicy";
import ShippingAndDeliveryPolicy from "../Components/RefundPolicy/ShippingAndDeliveryPolicy";

export default function AppRoutes() {

    const {pathname } = useLocation();


    return (
        <>
        <ScrollToTop/>
            {(pathname === "/login") ? 
            <Routes>
                {/* <Route path="/" element={<Login />} />/ */}
                <Route path="/login" element={<Login />} />
            </Routes>
            :
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/villas-list" element={<VillaList />} />
                    <Route path="/properties/:propertyId" element={<PropertyList/>}/>
                    <Route path="/category/1" element={<Properties/>} />
                    <Route path="/villa-details/:villaid" element={<VillaDetails />} />
                    <Route path='/chat' element={<Chat />} />
                    <Route path='/my-services' element={<ServiceList />} />
                    <Route path='/my-services/:servicesId' element={<ServicePage />} />
                    <Route path='service-form' element={<ServiceForm />} />
                    <Route path='/service-form/:servicesId' element={<EditService/>} />
                    <Route path="/Buypackages" element={<BuyPackages />} />
                    <Route path="/edit-profile" element={<Editprofile />} />
                    <Route path="/otp" element={<Otp />} />
                    <Route path="/favourites" element={<Favourites/>} />
                    <Route path="/Mylisting1" element={<Mylisting1 />}></Route>
                    <Route path="/Mylisting2/:id" element={<Mylisting2 />}></Route>
                    <Route path="/bumppost/:id" element={<Mylisting3 />}></Route>
                    <Route path="/list-property" element={<ListProperty />} />
                    <Route path="/post-ad-1" element={<PostAd1 />} />
                    {/* <Route path="/post-ad-2" element={<PostAd2 />} /> */}
                    {/* <Route path="/list-property-main" element={<Selection />}/> */}
                    <Route path="/selling-property" element={<SellingProperty/>}/>
                    <Route path="/selling-property/:propertyId" element={<EditProperty/>}/>
                    <Route path ="/categories" element={<CategoriesPage/>}/>
                    <Route path="/category/:categoryId" element={<Categories />} />
                    <Route path="/categorie/:servicesId" element={<Categoriepage />} />
                    <Route path="/my-jobs" element={<Jobs/>}/>
                    <Route path="/jobs" element={<JobsList/>}/>
                    <Route path="/jobs/:jobsId" element={<JobsPage/>}/>
                    <Route path ="/jobs-form" element={<JobsForm/>}/>
                    <Route path="/my-jobs/:jobsId" element={<JobsPage/>}/>
                    <Route path="/chat-popup" element={<Chatpopup />} />
                    <Route path="/invoices" element={<Invoices/>}/>
                    <Route path="/Terms&Conditions" element={<TermsAndConditions/>}/>
                    <Route path="/privacyPolicy" element={<PrivacyPolicy/>}/>
                    <Route path="/contact-us" element={<ContactUs/>}/>
<Route path="/Refundpolicy" element={<RefundPolicy/>}/>
<Route path="/shipping&deliverypolicy" element={<ShippingAndDeliveryPolicy/>}/>
                </Routes>
            </Layout>}
        </>
    )
}
