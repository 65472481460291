import React from 'react'
import '../../Styles/Terms.css'

const ShippingAndDeliveryPolicy = () => {

    const policySections = [
        {
          title: "Purpose",
          details: [
            "This policy outlines the terms and conditions related to the delivery of documents, property keys, and other real estate-related materials to clients.",
          ],
        },
        {
          title: "Scope",
          details: [
            "This policy applies to:",
            "Delivery of property-related documents (e.g., sale agreements, lease agreements, title deeds).",
            "Delivery of property keys to the buyer or tenant.",
            "Delivery of marketing materials for property listings.",
            "Other real estate-related items agreed upon by the parties.",
          ],
        },
        {
          title: "Delivery Options",
          details: [
            "We offer the following delivery methods:",
            "Digital Delivery: Soft copies of documents will be sent via email or secure document-sharing platforms.",
            "Physical Delivery: Hard copies of documents or keys will be delivered using courier services or through in-person handovers.",
            "In-Office Collection: Clients can opt to collect items from our office during business hours.",
          ],
        },
        {
          title: "Delivery Timeline",
          details: [
            "Digital Delivery: Documents will be emailed within [X business days] of finalization and approval.",
            "Physical Delivery: Items will be shipped within [X business days] after completion of necessary processing. Delivery timeframes depend on the courier service and destination.",
            "In-Office Collection: Items will be ready for collection within [X business days] of the request.",
          ],
        },
        {
          title: "Shipping Costs",
          details: [
            "Local Deliveries: [Flat rate/free delivery for certain items].",
            "International Deliveries: Charges will depend on the courier service and destination.",
            "Digital Delivery: No charge for email or document-sharing services.",
          ],
        },
        {
          title: "Delivery Confirmation",
          details: [
            "For all deliveries, confirmation will be provided via email or phone, including tracking information for couriered items.",
            "Clients must acknowledge receipt of the delivered items.",
          ],
        },
        {
          title: "Failed Delivery",
          details: [
            "If delivery fails due to:",
            "Incorrect Address Provided: The client is responsible for reshipping costs.",
            "Client Unavailability: A second delivery attempt may incur additional charges.",
            "Courier Issues: We will liaise with the courier service to resolve the issue and ensure redelivery.",
          ],
        },
        {
          title: "Responsibility",
          details: [
            "For Digital Delivery: [Your Business Name] ensures the confidentiality and accuracy of documents sent electronically.",
            "For Physical Delivery: While we take utmost care in packaging and shipping, we are not liable for delays caused by the courier service.",
          ],
        },
        {
          title: "Contact Information",
          details: [
            "For any inquiries or concerns regarding shipping and delivery, please contact us at:",
            "Phone: [Your Business Phone Number]",
            "Email: [Your Business Email Address]",
            "Office Address: [Your Business Address]",
          ],
        },
      ];

  return (
    <div className="container">
    <h1>Shipping and Delivery Policy</h1>
    {policySections.map((section, index) => (
      <div key={index} className="terms-content">
        <h2 class="term-title">{section.title}</h2>
        <ul>
          {section.details.map((detail, idx) => (
            <li key={idx} className='term-content'>{detail}</li>
          ))}
        </ul>
      </div>
    ))}
  </div>
  )
}

export default ShippingAndDeliveryPolicy