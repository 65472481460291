import React from 'react'
import { FaCircle } from 'react-icons/fa';

const PrivacyPolicy = () => {
    const privacy = [
        {
            title: "1. Introduction",
            content: [
                "Welcome to [First House]! Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you use our app, which offers real estate services, property listings, job postings, and related services.",
                "By using our app, you agree to the terms outlined in this Privacy Policy. If you do not agree, please do not use the app."
            ]
        },
        {
            title: "2. Information We Collect",
            content: [
                {
                    subheading: "a. Personal Information",
                    details: [
                        "We may collect the following personal information when you register or use our app:",
                        "Name, email address, and phone number.",
                        "Address and location data (if provided or enabled).",
                        "Payment information (if you purchase services)."
                    ]
                },
                {
                    subheading: "b. Non-Personal Information",
                    details: [
                        "We may also collect non-identifiable data such as:",
                        "Device information (e.g., device type, operating system, app version).",
                        "IP address and browsing data.",
                        "Usage statistics and analytics."
                    ]
                }
            ]
        },
        {
          title: "3. How We Use Your Information",
          content: [
            "We use your information to:",
            "Provide, operate, and improve our app and services.",
            "Facilitate property transactions and job postings.",
            "Send notifications, updates, and promotional offers.",
            "Respond to inquiries or customer support requests.",
            "Comply with legal obligations."
          ]
        },
        {
          title: "4. Sharing Your Information",
          content: [
            "We do not sell your information to third parties. However, we may share your information with:",
            "Service Providers: To process payments or deliver services.",
            "Business Partners: To facilitate property listings, jobs, or other services.",
            "Legal Authorities: If required by law or to protect our rights and users."
          ]
        },
        {
          title: "5. Data Security",
          content: [
            "We take reasonable steps to protect your data, including encryption and secure storage. However, no method of transmission over the internet or electronic storage is 100% secure. Use the app at your own risk."
          ]
        },
        {
          title: "6. Your Rights",
          content: [
            "Depending on your location, you may have the following rights:",
            "Access: Request a copy of your data.",
            "Correction: Update incorrect or incomplete information.",
            "Deletion: Request deletion of your personal information.",
            "Opt-Out: Disable certain data collection (e.g., marketing communications)."
          ]
        },
        {
          title: "7. Cookies and Tracking",
          content: [
            "We use cookies and similar technologies to:",
            "Remember your preferences.",
            "Analyze app performance and usage.",
            "Show relevant advertisements.",
            "You can manage cookies in your device or browser settings."
          ]
        },
        {
          title: "8. Third-Party Links",
          content: [
            "Our app may contain links to third-party websites or services. We are not responsible for their privacy practices, so we encourage you to review their policies."
          ]
        },
        {
          title: "9. Children’s Privacy",
          content: [
            "Our app is not intended for individuals under 18 years of age. We do not knowingly collect data from children."
          ]
        },
        {
          title: "10. Changes to This Privacy Policy",
          content: [
            "We may update this Privacy Policy from time to time. The latest version will be posted with the 'Effective Date.' Your continued use of the app indicates your acceptance of any changes."
          ]
        },
        {
          title: "11. Contact Us",
          content: [
            "If you have questions or concerns about this Privacy Policy, please contact us at:",
            "Email: firsthouseforyou@gmail.com",
            // "Address: H. No. 5-11/110/150, Brindavan Teachers Colony, Ameenpur, Sangareddy District, Telangana - 502032."
          ]
        }
      ];
      
  return (
<div className="container">
            <h1>Privacy Policy</h1>
            <br />
            <div>
            <h6 className="term-title">Welcome to First House!</h6> 
 <p className="term-content">First house is your one-stop solution for all real estate needs. Whether you want to buy, sell, 
or Lease (rent) properties, our app provides a seamless platform to explore and manage a 
wide range of real estate services.</p>
<h6 className="term-title">Privacy & Policy: </h6> 
 <p className="term-content">When registering a new first house user account, you will need to provide a phone 
number and e mail address to use as the account name and create a password. Your account 
and password will be encrypted and stored to our servers. You are completing account 
information while using various services; you may receive better service quality and user 
experience. We will collect your name, phone number and mail id, this information is vital to 
national security and defence. We retain personal information for the period necessary for the 
purpose of the information collection described in this privacy policy or as required by 
applicable laws. </p>

            </div>
            <div className="terms-content">
                {privacy.map((term, index) => (
                    <div key={index} className="term">
                        <h6 className="term-title">{term.title}</h6>
                        {Array.isArray(term.content) ? (
                            term.content.map((content, index) =>
                                typeof content === 'string' ? (
                                    <p className="term-content" key={index}>
                                        <FaCircle style={{ fontSize: '8px', marginRight: '8px', color: '#555' }} />
                                        {content}
                                    </p>
                                ) : (
                                    <div key={index}>
                                        <h6 className="term-subheading">{content.subheading}</h6>
                                        <ul>
                                            {content.details.map((detail, detailIndex) => (
                                                <li key={detailIndex} className="term-content">
                                                    {detail}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )
                            )
                        ) : (
                            <p className="term-content">{term.content}</p>
                        )}
                    </div>
                ))}
            </div>
            <div>
            <h6 className="term-title1">Office Address: </h6>
<p className='term-content'>M/s First House,<br></br> 
 H.No: 5-11/110/150, <br></br>
Brindavan Teachers Colony, <br></br> 
Beeramguda, Ameenpur,  <br></br>
Sangareddy District, <br></br> 
Telangana State-502032. <br></br></p>
            </div>
            <footer className="footer">
                © 2024 Your Company. All rights reserved.
            </footer>
        </div>
  )
}

export default PrivacyPolicy