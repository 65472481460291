import React, { useEffect, useState } from 'react';
import axios from 'axios';
import  { generateHTMLContent } from './generateHTMLContent'
// import EmptyInvoicesImage from '../assets/images/Emptyinvoices.svg';
import { BASE_URL } from "../../Services/BaseUrl";
import '../../Styles/invoices.css'
const Invoices = () => {
    const userId =localStorage.getItem('userId');
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    // const invoices = [
    //     { id: 1, number: "INV001", date: "2024-12-01", amount: "$500", status: "Paid" },
    //     { id: 2, number: "INV002", date: "2024-12-05", amount: "$700", status: "Pending" },
    //     { id: 3, number: "INV003", date: "2024-12-10", amount: "$300", status: "Paid" },
    //   ];

      const fetchInvoices=async()=>{
        try {
            const response = await axios.get(`${BASE_URL}/Advertisers/${userId}/invoice`)
            if (!userId) {
                console.error('User details not found or invalid');
                setLoading(false);
                return;
              }
        console.log(response.data,"invoices data")
            setInvoices(response.data);
        } catch (error) {
            console.error('Error fetching invoices', error);
            
        }
        finally {
            setLoading(false);
          }
   
      }

      useEffect(() => {
        fetchInvoices();
      }, []);

      const handlePrintPDF = async (invoice) => {
        const htmlContent = generateHTMLContent(invoice);
    
        const printWindow = window.open('', '', 'width=800,height=600');
        printWindow.document.write(htmlContent);
        printWindow.document.close();
        printWindow.print();
      };
    


      
    
      
  return (
    <div className="invoice-container">
      <header>
        <h1>Invoices</h1>
      </header>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {invoices.length === 0 ? (
            <div className="empty-invoice-container">
              {/* <img src={EmptyInvoicesImage} alt="Empty Invoices" /> */}
              <p>No invoices available</p>
            </div>
          ) : (
            <div className="invoice-list">
              {invoices.map((invoice) => (
                <div className="invoice-card" key={invoice.advertiserId}>
                  {/* <img
                    className="document-icon"
                    src="/assets/images/document.png"
                    alt="Document"
                  /> */}
                  <div className="invoice-details">
                    <p className="invoice-number">Invoice No : {invoice.invoiceNumber}</p>
                    <p className="invoice-price">₹{invoice.packagePrice}</p>
                    <div className='d-flex'>
                    <span className='invoice-number'>valid till </span>

                                        <p className="invoice-price">: 
  {new Date(invoice.packageEndDate).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })}
</p>
</div>

                  </div>
                  <button
                    className="print-button"
                    onClick={() => handlePrintPDF(invoice)}
                  >
                    Print Invoice
                  </button>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>

  )
}

export default Invoices