import React from 'react'
import '../../Styles/Terms.css'
import { FaCircle } from 'react-icons/fa';
const TermsAndConditions = () => {
    const terms = [
        {
          title: "1. Introduction",
          content: "Welcome to [First House]! By accessing or using our app, you agree to be bound by these Terms and Conditions. Please read them carefully before using the app. If you do not agree, please do not use our services."
        },
        {
          title: "2. Use of the App",
          content: [
            "You must be at least 18 years old to use this app.",
            "You agree to use the app only for lawful purposes and in compliance with all applicable laws and regulations.",
            "You are responsible for maintaining the confidentiality of your account and login credentials."
          ]
        },
        {
          title: "3. Intellectual Property",
          content: [
            "All content, logos, trademarks, and materials on the app are owned by [First House] or its licensors and are protected by copyright and other intellectual property laws.",
            "You may not reproduce, distribute, or create derivative works from the content without prior written consent."
          ]
        },
        {
          title: "4. User Responsibilities",
          content: [
            "You agree not to upload, post, or share any content that is illegal, offensive, defamatory, or violates third-party rights.",
            "You agree not to use the app to distribute spam or malicious software.",
            "You are solely responsible for the accuracy of any information you provide, including property listings, job postings, and other submissions."
          ]
        },
        {
          title: "5. Limitation of Liability",
          content: [
            "We strive to provide accurate and up-to-date information, but we do not guarantee the accuracy, completeness, or reliability of the app’s content.",
            "[First House] is not responsible for any direct, indirect, or consequential damages arising from your use of the app."
          ]
        },
        {
          title: "6. Third-Party Services",
          content: [
            "The app may include links or integrations with third-party services. We are not responsible for their content, terms, or practices.",
            "Your use of third-party services is subject to their respective terms and conditions."
          ]
        },
        {
          title: "7. Payment and Transactions",
          content: [
            "If you purchase any services through the app, you agree to provide accurate payment information.",
            "All transactions are subject to applicable taxes and fees.",
            "We are not liable for payment failures caused by your payment provider."
          ]
        },
        {
          title: "8. Termination",
          content: [
            "We reserve the right to terminate or suspend your account at any time, without notice, for violations of these Terms and Conditions.",
            "Upon termination, you must cease all use of the app and delete any downloaded content."
          ]
        },
        {
          title: "9. Changes to Terms",
          content: [
            "We may update these Terms and Conditions from time to time. The latest version will be posted with the 'Effective Date.'",
            "Continued use of the app constitutes your acceptance of any changes."
          ]
        },
        {
          title: "10. Governing Law",
          content: [
            "These Terms and Conditions are governed by and construed in accordance with the laws of India.",
            "Any disputes will be resolved exclusively in the courts of Sangareddy District, Telangana."
          ]
        },
        {
          title: "11. Contact Us",
          content: [
            "If you have any questions about these Terms and Conditions, please contact us at:",
            "Email: firsthouseforyou@gmail.com",
            "Address: H. No. 5-11/110/150, Brindavan Teachers Colony, Ameenpur, Sangareddy District, Telangana - 502032."
          ]
        }
      ];
  return (
<div class="container">
    <h1>Terms and Conditions</h1>
    {/* <p class="last-updated">Last updated on: December 26, 2024</p> */}
    <br></br>
    <div class="terms-content">
        {terms.map((term, index) => (
            <div key={index} class="term">
                <h6 className='term-title'>{term.title}</h6>
                {Array.isArray(term.content) ? (
                    term.content.map((content, index) =>   <p className="term-content" key={index}>
                    <FaCircle style={{ fontSize: '8px', marginRight: '8px', color: '#555' }} />
                    {content}
                  </p>)
                ) : (
                    <p className='term-content'>{term.content}</p>
                )}
            </div>
        ))}
    </div>
    <footer class="footer">
        © 2024 Your Company. All rights reserved.
    </footer>
</div>

  )
}

export default TermsAndConditions