import React, { useState, useEffect } from "react";
import appartment from "../../Assets/appartment.svg";
import locationImg from "../../Assets/location1.svg";
import Scale from "../../Assets/Scale.svg";
import likeicon from "../../Assets/likeicon.svg";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../../Services/BaseUrl";
import heart from '../../Assets/heart.svg';
import { useNavigate } from "react-router-dom";

const VillasList = ({
  favouriteId,
  propertyId,
  rooms,
  title,
  location,
  plantype,
  area,
  price,
  image,
  isInitiallyFavourite,
  fetchFavourites
}) => {
  const [isFavourite, setIsFavourite] = useState(isInitiallyFavourite);
  const [currentFavouriteId, setFavouriteId] = useState(favouriteId || 0);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const token=localStorage.getItem("token");
  console.log(title,"title in prop card")
  useEffect(() => {
    setFavouriteId(favouriteId);
    setIsFavourite(favouriteId > 0);
  }, [favouriteId]);

  const handleDetailsClick = () => {
    navigate(`/villa-details/${propertyId}`);
  };

  const handleAddToFavourites = async () => {
    if (!userId) {
      toast.warn("Please log in to add to favourites.");
      return;
    }

    const favourites = { favouritedBy: userId, propertyId };
    try {
      const response = await axios.post(`${BASE_URL}/Favourites`, favourites,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      const newFavouriteId = response.data.favouriteId;

      setIsFavourite(true);
      setFavouriteId(newFavouriteId);
      toast.success("Added to favourites!");
    } catch (error) {
      console.error("Error adding to favourites:", error);
      toast.error("Failed to add to favourites.");
    }
  };

  const handleRemoveFromFavourites = async () => {
    if (!userId) {
      toast.warn("Please log in to remove from favourites.");
      return;
    }

    if (currentFavouriteId && currentFavouriteId > 0) {
      try {
        await axios.post(`${BASE_URL}/Favourites/${currentFavouriteId}/delete`,{
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        });
        setIsFavourite(false);
        setFavouriteId(0);
        toast.success("Removed from favourites!");

        // Update the list in the parent component
        if (fetchFavourites) fetchFavourites();
      } catch (error) {
        console.error("Error removing from favourites:", error);
        toast.error("Failed to remove from favourites.");
      }
    } else {
      console.warn("Invalid favouriteId. Cannot remove from favourites.");
    }
  };

  return (
    <div className="villa-card">
      <div className="villa-image">
        <img 
          src={image} 
          alt={title} 
          loading="lazy" 
          onClick={handleDetailsClick} 
          onError={(e) => e.target.src = '../../Assets/logoimg.png'} 
        />
           {plantype === "Feature" && <p className="plantype">{plantype}</p>}
        <div
          className="card-fav-icon"
          style={{
            backdropFilter: "blur(5px)",
            background: "rgba(0, 45, 58, 0.50)",
            borderRadius: "6px",
            height: "30px",
            width: "30px",
            zIndex:"1"
          }}
        >
      
          {isFavourite ? (
            <img
              src={heart}
              alt="heart"
              onClick={handleRemoveFromFavourites}
              style={{ height: "30px", width: "30px" }}
            />
          ) : (
            <img
              src={likeicon}
              alt="like"
              onClick={handleAddToFavourites}
              style={{ height: "30px", width: "30px" }}
            />
          )}
        </div>
      </div>
      <div className="villa-card-content">
        <span className="villa-title">{title}</span>
        {/* <span className="villa-location">
          <img src={locationImg} loading="lazy" alt="Location" />
          {location}
        </span> */}
 <span className="villa-area">
  {(rooms > 0 || (area !== "null" && area !== null && area > 0)) && (
    <img src={Scale} loading="lazy" alt="Area" />
  )}
  {rooms > 0 && `${rooms} BHK`}
  {rooms > 0 && area !== "null" && area !== null && area > 0 && " - "}
  {area !== "null" && area !== null && area > 0 && `${area} Sq.feet`}
</span>

        <span className="villa-price"> ₹ {price}</span>
      </div>
    </div>
  );
};

export default VillasList;
