export const generateHTMLContent = (invoice) => {
    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Invoice</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 10px;
          }
          .container {
            padding: 20px;
            background: #ffffff;
            border: 1px solid #ddd;
            border-radius: 5px;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
          }
          h1 {
            text-align: center;
          }
          table {
            width: 100%;
            margin-top: 20px;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid #aaa;
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f2f2f2;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <h1>Invoice</h1>
          <p>Date: ${new Date(invoice.packageCreatedDate).toLocaleDateString()}</p>
          <p>Payment Order ID: ${invoice.payorderId}</p>
          <p>Invoice Number: ${invoice.invoiceNumber}</p>
          <table>
            <thead>
              <tr>
                <th>Package Name</th>
                <th>Package Price</th>
                <th>Plan Validity</th>
                <th>Number of Ads</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${invoice.packageName}</td>
                <td>₹${invoice.packagePrice}</td>
                <td>${invoice.planValidity}</td>
                <td>${invoice.noOfAdds}</td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </body>
      </html>
    `;
  };
  